import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppContext from "../../AppContext";
import loading from '../../images/loading-text.gif';

export default function DashboardMarketStats() {
    const dbContext = useContext(AppContext);

    return (
        <Container fluid>
            <Row className="stats-container">
                <Col className="dashboard-container stats-single-container" >
                    <Row style={{ height: "100%" }} className="align-items-center">
                        <Col xs={1} style={{ height: "100%", width: 8 }} >
                            <div className="stats-mark" style={{ backgroundColor: "#ff69b4" }}></div>
                        </Col>
                        <Col xs={11} sm={10} xl={11} className="text-center" >
                            {dbContext.loading.market && (
                                <img className="loading-text" src={loading} />
                            )}
                            {!dbContext.loading.market && (
                                <>
                                    <h6 className="dark">
                                        $ {new Intl.NumberFormat('en-US').format(dbContext.marketCapData.total_market_cap.toFixed(0))}
                                    </h6><Row className="align-items-center" style={{ justifyContent: "center" }}>
                                        <span className="text dark grayed" style={{ width: "auto" }}>Market Capitalization</span>
                                        <span className={`percentage-container ${dbContext.marketCapData.total_market_cap_change >= 0 ? "percentage-container-positive" : ""}`}>
                                            {dbContext.marketCapData.total_market_cap_change.toFixed(1) + " % "
                                                // (dbContext.marketCapData.total_market_cap_change > 0 ? (<>&uarr;</>) : (<>&darr;</>))
                                            }
                                        </span>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col className="dashboard-container stats-single-container" >
                    <Row style={{ height: "100%" }} className="align-items-center">
                        <Col xs={1} style={{ height: "100%", width: 8 }}>
                            <div className="stats-mark" style={{ backgroundColor: "#0c6" }}></div>
                        </Col>
                        <Col xs={11} sm={10} xl={11} className="text-center" >
                            {dbContext.loading.market && (
                                <img className="loading-text" src={loading} />
                            )}
                            {!dbContext.loading.market && (
                                <>
                                    <h6 className="dark">
                                        $ {new Intl.NumberFormat('en-US').format(dbContext.marketCapData.trading_volume.toFixed(0))}
                                    </h6>
                                    <Row className="align-items-center" style={{ justifyContent: "center" }}>
                                        <Col className="col-md-auto text-center">
                                            <span className="text dark grayed">24h Trading Volume</span>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col className="dashboard-container stats-single-container" >
                    <Row style={{ height: "100%" }} className="align-items-center">
                        <Col xs={1} style={{ height: "100%", width: 8 }} >
                            <div className="stats-mark" style={{ backgroundColor: "#ab4cfe" }}></div>
                        </Col>
                        <Col xs={11} sm={10} xl={11} className="text-center" >
                            {dbContext.loading.market && (
                                <img className="loading-text" src={loading} />
                            )}
                            {!dbContext.loading.market && (
                                <>
                                    <h6 className="dark">
                                        {dbContext.marketCapData.btc_market_cap.toFixed(2)} %
                                    </h6>
                                    <Row className="align-items-center">
                                        <Col className="text-center">
                                            <span className="text dark grayed">Bitcoin Market Cap Dominance</span>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}