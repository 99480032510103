
import checkmark from '../images/checkmark.svg';
import bg1 from '../images/bg-element-1.svg'
import bg1light from '../images/bg-element-1-light.svg'
import assetsDark from '../images/assetsdark.png';
import element1 from '../images/element-1.svg';
import { Button, Col, Container, Row } from "react-bootstrap";

export default function TopFooter({ showFeatures = true }) {
    return (
        <>
            <Container fluid className='container-home-3'>
                <Container hidden={!showFeatures}>
                <Row className='top-padding'>
                    <Col sm={12} md={6} className='relative'>
                        <img src={bg1} loading="lazy" alt="" className="absolute" />
                        <img src={bg1light} loading="lazy" alt="" className="absolute" />
                        <img src={assetsDark} loading="lazy" alt="" style={{ zIndex: 1, position: 'relative' }} />

                    </Col>
                    <Col sm={12} md={6} className='relative'>
                        <h3 className='dark outline-h3-dark'>Key Features that Unlock</h3>
                        <h2 className="dark">THE FUTURE OF DIGITAL ASSET MANAGEMENT</h2>
                        <div className="dark text top-bottom-padding">Take complete control of your digital assets. Our user-friendly, state-of-the-art dashboard offers you the freedom to directly manage your investments, on-chain assets, and digital assets
                        </div>
                        <Row fluid>
                            <Col sm={12} md={6}>
                                <Row className='align-items-center'>
                                    <Col xs={2}><img src={checkmark} loading="lazy" alt="" className='checkmark' /></Col>
                                    <Col xs={10} className='text-start'><span className='text bold'>Modular Architecture</span></Col>
                                </Row>
                                <Row className='align-items-center'>
                                    <Col xs={2}><img src={checkmark} loading="lazy" alt="" className='checkmark' /></Col>
                                    <Col xs={10} className='text-start'><span className='text bold'>Contractual Autonomy</span></Col>
                                </Row>
                                <Row className='align-items-center'>
                                    <Col xs={2}><img src={checkmark} loading="lazy" alt="" className='checkmark' /></Col>
                                    <Col xs={10} className='text-start'><span className='text bold'>High-Level Security Protocol</span></Col>
                                </Row>
                            </Col>
                            <Col sm={12} md={6}>
                                <Row className='align-items-center'>
                                    <Col xs={2}><img src={checkmark} loading="lazy" alt="" className='checkmark' /></Col>
                                    <Col xs={10} className='text-start'><span className='text bold'>Direct Control Dashboard</span></Col>

                                </Row>
                                <Row className='align-items-center'>
                                    <Col xs={2}><img src={checkmark} loading="lazy" alt="" className='checkmark' /></Col>
                                    <Col xs={10} className='text-start'><span className='text bold'>Seamless API Integration</span></Col>

                                </Row>
                                <Row className='align-items-center'>
                                    <Col xs={2}><img src={checkmark} loading="lazy" alt="" className='checkmark' /></Col>
                                    <Col xs={10} className='text-start'><span className='text bold'>Customizable Financial Solutions</span></Col>

                                </Row>
                            </Col>
                        </Row>
                        <Button className="button dark-button"
                            href="/contact"
                        >Learn More</Button>
                    </Col>
                </Row>
            </Container >
            <Container>
                <Row className='top-bottom-padding'>
                    <Col sm={12} md={6} >
                        <img src={element1} loading="lazy" alt="" className="top-bottom-padding" />
                        <h1 className="dark">READY TO <span className='violet'>TAKE CONTROL</span> OF YOUR DIGITAL ASSETS?</h1>

                    </Col>
                    <Col sm={12} md={6}>
                        <div className="dark text top-bottom-padding">Unlock a world of features designed to put you in the driver's seat.
                            <br />Seamless integration, real-time insights, and more await. Contact us for more information.</div>
                        <Button className="button dark-button"
                            href="/contact"
                        >Get In Touch</Button>
                    </Col>
                </Row>
            </Container>
        </Container >
        </>
    )
}