import Footer from "../components/Footer";
import Header from "../components/Header";
import stakingdark from '../images/Stakingdark.png'
import dash from '../images/dash.png'
import dash2 from '../images/dash2.png'
import { Col, Container, Row } from "react-bootstrap";
import TopFooter from "./TopFooter";


export default function About() {
    return (
        <>
            <Container fluid className='container-about-1'>
                <Header />
                <Container>
                    <Row className='top-padding'>
                        <Col className="text-center">
                            <h1 className="dark">OUR STORY</h1>
                            <div className="dark text top-bottom-padding">Unlocking the Future of Digital Asset Management
                                <br />Your Assets, Your Terms: Ultimate Control
                            </div>

                            <img src={stakingdark} loading="lazy" alt="" className="bottom-padding" />

                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className='container-about-2'>
                <Container>
                    <Row className='top-bottom-padding align-items-center'>
                        <Col sm={12} md={6} >
                            <img src={dash} loading="lazy" alt="" className="top-bottom-padding" />
                        </Col>
                        <Col sm={12} md={6}>
                            <h3 className="dark">IN AN ERA WHERE DIGITAL ASSETS ARE REVOLUTIONIZING THE FINANCIAL LANDSCAPE, WE NOTICED A GLARING ISSUE.</h3>
                            <div className="dark text">
                                Traditional financial institutions like small private banks and family offices were hesitant to dive into the digital asset world. They had concerns about transparency, control, and the adaptability of existing solutions to meet their specific needs. That's where the idea for Umbrella was born.
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className='top-bottom-padding align-items-center'>
                        <Col sm={12} md={6}>
                            <h3 className="dark">
                                WE AIMED TO BRIDGE THE GAP BETWEEN TECHNOLOGY AND FINANCE.
                            </h3>
                            <div className="dark text">
                                To provide a solution that wasn't just secure, but also directly controllable and transparent. With the expertise of industry veterans and tech-savvy innovators, we've built Umbrella—a platform that caters to high-value investors, offering them an unparalleled level of control and security.
                            </div>
                        </Col>
                        <Col sm={12} md={6} >
                            <img src={dash2} loading="lazy" alt="" className="top-bottom-padding" />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <TopFooter />
            <Footer />
        </>
    )
}