import Header from '../components/Header';
import { Col, Container, Row } from 'react-bootstrap';
import TopFooter from './TopFooter';
import Footer from '../components/Footer';

export default function Error404() {
    return (
        <>
            <Container fluid className='container-home-1'>
                <Header />
                <Container>
                    <Row className='top-padding'>
                        <Col className="text-center">
                            <h1 className="dark">
                                Error 404
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <TopFooter showFeatures={false} />
            <Footer />
        </>
    );
}