import Footer from "../components/Footer";
import Header from "../components/Header";

import frame74 from '../images/Frame-74.png';
import seven from '../images/07.svg';
import eight from '../images/08.svg';
import nine from '../images/09.svg';
import ten from '../images/10.svg';
import eleven from '../images/11.svg';
import twelve from '../images/12.svg';
import { Col, Container, Row } from "react-bootstrap";
import TopFooter from "./TopFooter";


export default function Features() {
    return (
        <>
            <Container fluid className='container-features-1'>
                <Header />
                <Container>
                    <Row className='top-padding'>
                        <Col className="text-center">
                            <h1 className="dark">
                                KEY FEATURES
                            </h1>
                            <div className="dark text top-bottom-padding">
                                Unlocking the Future of Digital Asset Management
                                <br />Your Assets, Your Terms: Ultimate Control
                            </div>

                            <img src={frame74} loading="lazy" alt="" className="bottom-padding" />

                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className='container-features-2 text-center'>
                <h2 className="dark top-padding">Why Choose Umbrella?</h2>
                <Row className='top-bottom-padding'>
                    <Col sm={12} md={4} className='text-center'>
                        <img src={seven} loading="lazy" alt="" />
                        <h3 className="dark top-bottom-padding">
                            MODULAR ARCHITECTURE
                        </h3>
                        <div className="dark text">
                            Choose the services that fit your specific needs with our modular approach. We offer digital asset management a la carte, so you only get what you use.
                        </div>

                    </Col>
                    <Col sm={12} md={4} className='text-center'>
                        <img src={eight} loading="lazy" alt="" />
                        <h3 className="dark top-bottom-padding">
                            CONTRACTUAL AUTONOMY
                        </h3>
                        <div className="dark text">
                            Our platform lets you manage arrangements directly with multiple service providers, removing the need for middlemen and simplifying the chain of custody.
                        </div>
                    </Col>
                    <Col sm={12} md={4} className='text-center'>
                        <img src={nine} loading="lazy" alt="" />
                        <h3 className="dark top-bottom-padding">
                            HIGH-LEVEL SECURITY PROTOCOLS
                        </h3>
                        <div className="dark text">
                            We hold your digital assets to the highest security standards. Our protocols ensure that only you have control and access to your investments.
                        </div>
                    </Col>
                </Row>
                <Row className='top-bottom-padding'>
                    <Col sm={12} md={4} className='text-center'>
                        <img src={ten} loading="lazy" alt="" />
                        <h3 className="dark top-bottom-padding">
                            DIRECT CONTROL DASHBOARD
                        </h3>
                        <div className="dark text">
                            Choose the services that fit your specific needs with our modular approach. We offer digital asset management a la carte, so you only get what you use.
                        </div>

                    </Col>
                    <Col sm={12} md={4} className='text-center'>
                        <img src={eleven} loading="lazy" alt="" />
                        <h3 className="dark top-bottom-padding">
                            SEAMLESS API INTEGRATION
                        </h3>
                        <div className="dark text">
                            Our platform lets you manage arrangements directly with multiple service providers, removing the need for middlemen and simplifying the chain of custody.
                        </div>
                    </Col>
                    <Col sm={12} md={4} className='text-center'>
                        <img src={twelve} loading="lazy" alt="" />
                        <h3 className="dark top-bottom-padding">
                            CUSTOMIZABLE FINANCIAL SOLUTIONS
                        </h3>
                        <div className="dark text">
                            We hold your digital assets to the highest security standards. Our protocols ensure that only you have control and access to your investments.
                        </div>
                    </Col>
                </Row>
            </Container>
            <TopFooter showFeatures={false} />
            <Footer />

        </>
    )
}