import axios from 'axios';
import { Context } from 'vm';
import { Chain, PeriodPriceDB, PriceDB, TokenComposition, TokenDB, TransactionDB, WalletDB } from './Types';

const BLOCKCHAIR_API_KEY = "A___g66B5N5KaHk1J41DIPskFbmSjO4a";
const BLOCKCHAIR_API_URL = "https://api.blockchair.com";
const MORALIS_API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjJlZjlkODVkLTM3NjUtNDYyMi04NWE4LTA2MGNmMWYyNDdjNCIsIm9yZ0lkIjoiMTQ1MTAwIiwidXNlcklkIjoiMTQ0NzQ1IiwidHlwZSI6IlBST0pFQ1QiLCJ0eXBlSWQiOiI4NmY1MDg2YS0xYTQ5LTQ2MGYtODJlMC0wMzRkYTM1OGEyMjciLCJpYXQiOjE3MDYyOTUwNTUsImV4cCI6NDg2MjA1NTA1NX0.WBueoI9tL3qHAZx7dHz9hxlUKOR1XVYCwASvMcm09Xc";
const COINGEKO_API_KEY = "CG-RuzTEMrpaZA3m1pDB4sitgwr";
const COINGEKO_API_URL = "https://api.coingecko.com/api/v3";
const COINRANKING_API_KEY = "coinranking58d1343e3add413742603a94e9bd81c999de00930763b2aa";
const COINRAKNKING_API_URL = "https://api.coinranking.com/v2";
const NEWS_API_URL = "https://newsapi.org/v2/everything";
const NEWS_API_KEY = "ddc9fa25e57841b288772d9437b52371";

export const chainType = {
    ETH: {
        MORALIS: "eth",
        MORALIS_API_URL: "https://deep-index.moralis.io/api/v2.2",
        BLOCKCHAIR: "ethereum",
        symbol: "ETH"
    },
    BTC: {
        BLOCKCHAIR: "bitcoin",
        symbol: "BTC"
    },
    SOL: {
        MORALIS_API_URL: "https://solana-gateway.moralis.io",
        symbol: "BTC"
    }
};
const currency = "usd";

export function getWalletsTotalBalance(dbContext: Context, chainFilter = null, visibility: boolean = true, format: boolean = true): (string | number) {
    let totalBalance: number = 0;
    let wallets = dbContext.wallets;
    if (chainFilter) {
        wallets = wallets.filter((wallet: WalletDB) => wallet.chain == chainFilter);
    }
    if (visibility) {
        wallets = wallets.filter((wallet: WalletDB) => wallet.visibility == visibility);
    }
    wallets.forEach((wallet: WalletDB) => totalBalance += Number(getSingleWalletBalance(dbContext, wallet.address, false)));

    return format ? Intl.NumberFormat('en-US').format(totalBalance) : Number(totalBalance);
}
export function getSingleWalletBalance(dbContext: Context, address: string, format: boolean = true): (string | number) {
    const wallet = dbContext.wallets.find((wallet: WalletDB) => wallet.address == address);
    let totalBalance = 0;
    wallet.tokens?.forEach((token: TokenDB) => {
        const price = dbContext.prices.find((price: PriceDB) => price.symbol.toLowerCase() == token.symbol.toLowerCase());
        if (price) {
            totalBalance += token.amount * price.current_price;
        }
    });
    return format ? Intl.NumberFormat('en-US').format(totalBalance) : Number(totalBalance);
}
export function getTotalTokens(dbContext: Context, visibility: boolean = true) {
    let totalTokens: TokenDB[] = [];
    let wallets = dbContext.wallets;
    if (visibility) {
        wallets = wallets.filter((wallet: WalletDB) => wallet.visibility == visibility);
    }
    wallets.forEach((wallet: WalletDB) => {
        wallet.tokens?.forEach(token => {
            if (!totalTokens.find(totalToken => totalToken.symbol.toLowerCase() == token.symbol.toLowerCase())) {
                totalTokens.push(token);
            }
        })
    })
    return totalTokens.length;
}
export function getWalletsTotalBalanceByPeriod(dbContext: Context, chainFilter: Chain | boolean, timePeriod: string, columns: number, visibility: boolean = true) {
    let wallets = dbContext.wallets;
    if (chainFilter) {
        wallets = wallets.filter((wallet: WalletDB) => wallet.chain == chainFilter);
    }
    if (visibility) {
        wallets = wallets.filter((wallet: WalletDB) => wallet.visibility == visibility);
    }
    const balances = [];
    for (let i = 0; i < columns; i++) {
        let totalBalance = 0;
        wallets.forEach((wallet: WalletDB) => {
            wallet.tokens?.forEach(token => {
                const price = dbContext.prices.find((price: PriceDB) => price.symbol.toLowerCase() == token.symbol.toLowerCase());
                if (price) {
                    totalBalance += token.amount * price[timePeriod][columns - i - 1].value;
                }
            });
        })
        balances.push(totalBalance);
    }
    return balances;
}

export function getWalletTokensComposition(dbContext: Context, visibility = true): TokenComposition[] {
    const topTokens: TokenComposition[] = [];
    let wallets = dbContext.wallets;
    if (visibility) wallets = wallets.filter((wallet: WalletDB) => wallet.visibility);
    wallets.forEach((wallet: WalletDB) =>
        wallet.tokens?.forEach((token: TokenDB) => {
            const existingIndex: number = topTokens.findIndex(topToken => topToken.symbol.toLowerCase() == token.symbol.toLowerCase())
            if (existingIndex >= 0) {
                topTokens[existingIndex].amount = topTokens[existingIndex].amount + token.amount;
            } else {
                topTokens.push({
                    name: token.name,
                    symbol: token.symbol,
                    amount: token.amount,
                } as TokenComposition)
            }
        })
    );
    return topTokens
        .map(token => {
            const price = dbContext.prices.find((price: PriceDB) => price.symbol.toLowerCase() == token.symbol.toLowerCase());
            if (price) {
                const priceChange = (price.day[0].value - price.day[price.day.length - 1].value) / price.day[0].value;
                token.value = token.amount * (price ? price.current_price : 0);
                token.icon = price.icon;
                token.price = price.current_price;
                token.priceChange = priceChange;
            }
            return token;
        })
        .filter(token => token.value);
}


function getFormattedPriceByGroup(prices: any[], timeperiod: string, current: number): PeriodPriceDB[] {
    prices = prices.map(price => {
        price.time = (new Date(price.timestamp * 1000)).toJSON();
        return price;
    });
    const formattedPrices: PeriodPriceDB[] = [];
    let expectedPriceLenght = 0;
    let arrayToOperate = prices;
    switch (timeperiod) {
        case "day":
            expectedPriceLenght = 12;
            break;
        case "week":
            expectedPriceLenght = 7;
            const newArrayWeek = prices.slice(0, expectedPriceLenght);
            for (let i = 0; i < newArrayWeek.length; i++) {
                formattedPrices.push({
                    timestamp: (new Date(newArrayWeek[i].timestamp * 1000)).toJSON(),
                    value: Number([newArrayWeek[i].price])
                });
            }
            formattedPrices[0] = {
                timestamp: (new Date()).toJSON(),
                value: current
            }
            return formattedPrices;
        case "month":
            expectedPriceLenght = 30;
            arrayToOperate = prices.slice(0, expectedPriceLenght);
            expectedPriceLenght = expectedPriceLenght / 2;
            break;
        case "quarter":
            expectedPriceLenght = 90;
            arrayToOperate = prices.slice(0, expectedPriceLenght);
            expectedPriceLenght = expectedPriceLenght / 5;
            break;
        case "year":
            expectedPriceLenght = 12;
            break;
        default:
            break;
    }
    const interval = (arrayToOperate.length - 1) / (expectedPriceLenght - 1);
    for (let i = 0; i < expectedPriceLenght; i++) {
        const index = Math.round(i * interval);
        formattedPrices.push({
            timestamp: (new Date(arrayToOperate[index].timestamp * 1000)).toJSON(),
            value: Number([arrayToOperate[index].price])
        });
    }
    if (formattedPrices.length < expectedPriceLenght) {
        formattedPrices.push({
            timestamp: (new Date(arrayToOperate[arrayToOperate.length - 1].timestamp * 1000)).toJSON(),
            value: Number([arrayToOperate[arrayToOperate.length - 1].price])
        });
    }
    formattedPrices[0] = {
        timestamp: (new Date()).toJSON(),
        value: current
    }
    return formattedPrices;
}

// async function getSingleTokenPriceCoinGeko(token: TokenDB) {
//     let nativePriceYear = null;
//     let nativePriceDay = null;
//     if (token.type == "NATIVE") {
//         nativePriceYear = await axios.get(`${COINGEKO_API_URL}/coins/${chainType[token.type].COINGEKO}/market_chart?days=365&vs_currency=${currency}`);
//         nativePriceDay = await axios.get(`${COINGEKO_API_URL}/coins/${chainType[token.type].COINGEKO}/market_chart?days=1&vs_currency=${currency}`);
//     } else {
//         nativePriceYear = await axios.get(`${COINGEKO_API_URL}/coins/${chainType[token.type].COINGEKO}/contract/${token.address}/market_chart?days=365&vs_currency=${currency}`);
//         nativePriceDay = await axios.get(`${COINGEKO_API_URL}/coins/${chainType[token.type].COINGEKO}/contract/${token.address}/market_chart?days=1&vs_currency=${currency}`);
//     }
//     return {
//         symbol: token.symbol,
//         current_price: nativePriceDay.data.prices[nativePriceDay.data.prices.length - 1][1],
//         day_prices: getFormattedPriceByGroup(nativePriceDay.data.prices, "day"),
//         week_prices: getFormattedPriceByGroup(nativePriceYear.data.prices, "week"),
//         month_prices: getFormattedPriceByGroup(nativePriceYear.data.prices, "month"),
//         quarter: getFormattedPriceByGroup(nativePriceYear.data.prices, "quarter"),
//         year_prices: getFormattedPriceByGroup(nativePriceYear.data.prices, "year"),
//     };
// }

async function getSingleTokenPriceCoinRanking(token: TokenDB): Promise<PriceDB | undefined> {
    const config = {
        headers: {
            'x-access-token': 'coinranking58d1343e3add413742603a94e9bd81c999de00930763b2aa'
        }
    };
    const search = await axios.get(`${COINRAKNKING_API_URL}/coins?symbols=${token.symbol}`, config);
    if (
        search.data.status != "success" || !search.data.data.coins.find((coin: any) => coin.price != null)
    ) return undefined;
    const coin = search.data.data.coins.filter((coin: any) => coin.price != null)[0];
    const nativePriceDay = await axios.get(`${COINRAKNKING_API_URL}/coin/${coin.uuid}/history?timePeriod=24h`, config);
    const nativePriceYear = await axios.get(`${COINRAKNKING_API_URL}/coin/${coin.uuid}/history?timePeriod=1y`, config);

    const dayArray = nativePriceDay.data.data.history;
    const yearArray = nativePriceYear.data.data.history;
    return {
        uuid: coin.uuid,
        symbol: coin.symbol,
        name: coin.name,
        icon: coin.iconUrl,
        current_price: Number(coin.price),
        day: getFormattedPriceByGroup(dayArray, "day", Number(coin.price)),
        week: getFormattedPriceByGroup(yearArray, "week", Number(coin.price)),
        month: getFormattedPriceByGroup(yearArray, "month", Number(coin.price)),
        quarter: getFormattedPriceByGroup(yearArray, "quarter", Number(coin.price)),
        year: getFormattedPriceByGroup(yearArray, "year", Number(coin.price)),
    };
}

export async function getTokensPrice(wallets: WalletDB[], dbContext: Context) {
    try {
        const tokensToQuery: TokenDB[] = [];
        wallets.forEach((wallet: WalletDB) => wallet.tokens?.forEach(walletToken => {
            if (!tokensToQuery.find(token => token.symbol.toLowerCase() == walletToken.symbol.toLowerCase())) {
                tokensToQuery.push(walletToken);
            }
        }));
        const prices: PriceDB[] = [];//getSingleTokenPriceMoralis(tokenContracts.filter(token => !token.native));
        const coinrankingResults = await Promise.all(tokensToQuery.map((token: TokenDB) => getSingleTokenPriceCoinRanking(token).catch(error => {
            console.log("could not retrieve token " + token.symbol + " from coin ranking");
        })));
        coinrankingResults.forEach(result => {
            if (result) {
                prices.push(result);
            }
        })
        dbContext.setPrices(prices);
        const pricesLoading = dbContext.loading;
        pricesLoading.price = false;
        dbContext.setLoading(pricesLoading);
        //console.log(prices);
    } catch (err) {
        console.error(err);
        alert("An error occured while fetching token price");
    }
}

/* ETHEREUM */
export async function getEthereumBalance(address: string) {
    try {
        const resBalance = await axios.get(`${chainType.ETH.MORALIS_API_URL}/${address}/balance?chain=${chainType.ETH.MORALIS}`, {
            headers: {
                "X-API-Key": MORALIS_API_KEY,
            }
        });
        const res = await axios.get(`${chainType.ETH.MORALIS_API_URL}/${address}/erc20?chain=${chainType.ETH.MORALIS}`, {
            headers: {
                "X-API-Key": MORALIS_API_KEY,
            }
        });
        const tokens: TokenDB[] = res.data.map((token: any) => {
            return {
                name: token.name,
                symbol: token.symbol,
                amount: Number(token.balance) / Math.pow(10, Number(token.decimals)),
                address: token.token_address,
                type: "ERC20"
            };
        });
        tokens.push({
            name: "Ethereum",
            symbol: "ETH",
            amount: Number(resBalance.data.balance) / Math.pow(10, 18),
            address: "",
            type: "NATIVE"
        });
        //console.log("tokens: " + tokens);
        return tokens;
    } catch (err) {
        console.error(err);
        console.log("An error occurred while fetch ETH tokens for address " + address);
        //alert("An error occured while fetching ETH address balance");
    }
}

export async function getEthereumTransactions(address: string): Promise<TransactionDB[] | undefined> {
    try {
        const res = await axios.get(`${chainType.ETH.MORALIS_API_URL}/${address}/verbose?chain=eth`, {
            headers: {
                "X-API-Key": MORALIS_API_KEY,
            }
        });
        const transactions: TransactionDB[] = res.data.result.filter((transaction: any) => transaction.amount != 0).map((transaction: any) => {
            const inTransaction = transaction.to_address.toLowerCase() == address.toLowerCase();
            return {
                hash: transaction.hash,
                type: inTransaction ? "IN" : "OUT",
                fee: (transaction.receipt_gas_used * transaction.gas_price) / Math.pow(10, 18),
                timestamp: transaction.block_timestamp.slice(0, 19),
                chain: chainType.ETH.symbol,
                // token_address: transaction.decoded_call.params.find(param => param.name == "token").value,
                token: chainType.ETH.symbol,
                amount: transaction.value / Math.pow(10, 18) * (inTransaction ? 1 : -1),
            };
        });
        //console.log("transactions: " + transactions);
        return transactions;
    } catch (err) {
        console.error(err);
        console.log("An error occured while fetching ETH transactions for address " + address);
        //alert("An error occured while fetching address balance");
    }
}

/* BITCOIN */
export async function getBitcoinBalanceWithTransaction(address: string): Promise<{ tokens: TokenDB[], transactions: TransactionDB[] } | undefined> {
    try {
        const res = await axios.get(`${BLOCKCHAIR_API_URL}/${chainType.BTC.BLOCKCHAIR}/dashboards/address/${address}?key=${BLOCKCHAIR_API_KEY}&transaction_details=true&state=latest&limit=30`);

        const output = {
            tokens: [{
                name: "Bitcoin",
                symbol: "BTC",
                amount: Number(res.data.data[address].address.balance) / Math.pow(10, 8),
                address: "",
                type: "NATIVE"
            }],
            transactions: res.data.data[address].transactions.map((transaction: any) => {
                return {
                    hash: transaction.hash,
                    timestamp: transaction.time.replace(" ", "T") + "",
                    type: transaction.value > 0 ? "IN" : "OUT",
                    chain: chainType.BTC.symbol,
                    token: chainType.BTC.symbol,
                    amount: transaction.balance_change / Math.pow(10, 8),
                    fee: 0 //TODO
                }
            })
        }
        //console.log("tokens: " + tokens);
        return output;
    } catch (err) {
        console.error(err);
        console.log("An error occurred while fetch BTC tokens for address " + address);
        //alert("An error occured while fetching BTC address balance");
    }
}

/* SOLANA */
/* ETHEREUM */
export async function getSolanaBalance(address: string) {
    try {
        const resPortfolio = await axios.get(`${chainType.SOL.MORALIS_API_URL}/account/mainnet/${address}/portfolio`, {
            headers: {
                "X-API-Key": MORALIS_API_KEY,
            }
        });
        const tokens: TokenDB[] = resPortfolio.data.tokens.map((token: any) => {
            return {
                name: token.name,
                symbol: token.symbol,
                amount: Number(token.amountRaw) / Math.pow(9, Number(token.decimals)),
                address: token.associatedTokenAddress,
                type: "SOL"
            };
        });
        tokens.push({
            name: "Solana",
            symbol: "SOL",
            amount: Number(resPortfolio.data.nativeBalance.solana),
            address: "",
            type: "NATIVE"
        });
        //console.log("tokens: " + tokens);
        return tokens;
    } catch (err) {
        console.error(err);
        console.log("An error occurred while fetch ETH tokens for address " + address);
        //alert("An error occured while fetching ETH address balance");
    }
}

export async function getSolanaTransactions(address: string): Promise<TransactionDB[] | undefined> {
    try {
        const res = await axios.get(`${chainType.ETH.MORALIS_API_URL}/${address}/verbose?chain=eth`, {
            headers: {
                "X-API-Key": MORALIS_API_KEY,
            }
        });
        const transactions: TransactionDB[] = res.data.result.filter((transaction: any) => transaction.amount != 0).map((transaction: any) => {
            const inTransaction = transaction.to_address.toLowerCase() == address.toLowerCase();
            return {
                hash: transaction.hash,
                type: inTransaction ? "IN" : "OUT",
                fee: (transaction.receipt_gas_used * transaction.gas_price) / Math.pow(10, 18),
                timestamp: transaction.block_timestamp.slice(0, 19),
                chain: chainType.ETH.symbol,
                // token_address: transaction.decoded_call.params.find(param => param.name == "token").value,
                token: chainType.ETH.symbol,
                amount: transaction.value / Math.pow(10, 18) * (inTransaction ? 1 : -1),
            };
        });
        //console.log("transactions: " + transactions);
        return transactions;
    } catch (err) {
        console.error(err);
        console.log("An error occured while fetching ETH transactions for address " + address);
        //alert("An error occured while fetching address balance");
    }
}

/* MARKET DATA */
export async function getMarketCapData(dbContext: Context) {
    try {
        const res = await axios.get(`${COINGEKO_API_URL}/global`);
        const marketData = {
            total_market_cap: res.data.data.total_market_cap.usd,
            total_market_cap_change: res.data.data.market_cap_change_percentage_24h_usd,
            btc_market_cap: res.data.data.market_cap_percentage.btc,
            trading_volume: res.data.data.total_volume.usd,
        }
        dbContext.setMarketCapData(marketData);
        const marketLoading = dbContext.loading;
        marketLoading.market = false;
        dbContext.setLoading(marketLoading);
    } catch (err) {
        console.error(err);
        alert("An error occured while fetching global market cap");
    }
}

export async function getTopRakingCoins(dbContext: Context) {
    try {
        const config = {
            headers: {
                'x-access-token': 'coinranking58d1343e3add413742603a94e9bd81c999de00930763b2aa'
            }
        };
        const topCoins = await axios.get(`${COINRAKNKING_API_URL}/coins?timePeriod=24h&orderBy=24hVolume&limit=5`, config);
        if (
            topCoins.data.status != "success"
        ) return undefined;
        dbContext.setTopRankingCoins(topCoins.data.data.coins);
        const topCoinsLoading = dbContext.loading;
        topCoinsLoading.topCoins = false;
        dbContext.setLoading(topCoinsLoading);
    } catch (err) {
        console.error(err);
        alert("An error occured while fetching global market cap");
    }
}

export async function getLatestNews(dbContext: Context) {
    try {
        const yesterday = (new Date(Date.now() - 86400000)).toJSON().slice(0, 10);
        const news = await axios.get(`${NEWS_API_URL}?apiKey=${NEWS_API_KEY}&q=crypto&from=${yesterday}&searchIn=title,description`);
        dbContext.setNews(news.data.articles);
        const newsLoading = dbContext.loading;
        newsLoading.topCoins = false;
        dbContext.setLoading(newsLoading);
    } catch (err) {
        console.error(err);
        // alert("An error occured while fetching latest news");
    }
}

// export async function getEthereumBalance(address) {
//     try {
//         const res = await axios.get(`https://api.blockchair.com/ethereum/dashboards/address/${address}?key=${BLOCKCHAIR_API_KEY}&erc_20=true`, {
//         })
//         const response = res.data;
//         const tokens = response.data[address.toLowerCase()].layer_2.erc_20.map(token => {
//             return {
//                 name: token.token_name,
//                 symbol: token.token_symbol,
//                 balance: token.balance_approximate,
//                 address: token.token_address,
//                 type: "ERC20"
//             };
//         });
//         tokens.push({
//             name: "ETHEREUM",
//             symbol: "ETH",
//             balance: Number(res.data.data[address.toLowerCase()].address.balance)/Math.pow(10, 18),
//             address: "",
//             type: "NATIVE"
//         });
//         console.log("tokens: " + tokens);
//         return tokens;
//     } catch (err) {
//         console.error(err);
//         alert("An error occured while fetching address balance");
//     }
// }

export function identifyBlockchain(address: string): Chain {
    const solanaWalletRegex = /^([1-9A-HJ-NP-Za-km-z]{44})$/;

    if (address.startsWith("1") || address.startsWith("3")) {
        return Chain.BTC;
    } else if (address.startsWith("0x")) {
        return Chain.ETH;
        // } else if (address.startsWith("L") || address.startsWith("M")) {
        //     return "LTC";
    } else if (solanaWalletRegex.test(address)) {
        return Chain.SOL;
        // } else if (address.startsWith("cosmos")) {
        //     return "ATOM";
    } else {
        return Chain.NONE;
    }
}

export function getExternalUrl(chain: Chain, type: string, hash: string): string {
    switch (chain) {
        case Chain.ETH:
            if (type == "address") return "https://etherscan.io/address/" + hash;
            if (type == "transaction") return "https://etherscan.io/tx/" + hash;
            break;
        case Chain.BTC:
            if (type == "address") return "https://blockchair.com/it/bitcoin/address/" + hash;
            if (type == "transaction") return "https://blockchair.com/it/bitcoin/transaction/" + hash;
            break;
        case Chain.SOL:
            if (type == "address") return "https://explorer.solana.com/address/" + hash;
            if (type == "transaction") return "https://explorer.solana.com/tx/" + hash;
            break;
        default:
            return "";
    }
    return "";
}