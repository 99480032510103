import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DashboardMarketStats from "../dashboardMarket/DashboardMarketStats";
import DashboardWalletAllTokens from "../dashboardWallet/DashboardWalletAllTokens";
import DashboardHomeGraph from "./DashboardHomeGraph";

interface DashboardGraphViewProps {
    goToWalletPage: any;
}

const DashboardGraphView: React.FC<DashboardGraphViewProps> = ({ goToWalletPage }) => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="text-start">
                        <h4 className="dark">Dashboard</h4>
                    </Col>
                </Row>
                <Row>
                    <DashboardMarketStats />
                </Row>
                <Row>
                    <DashboardHomeGraph goToWalletPage={goToWalletPage} />
                </Row>
                <Row>
                    <Col >
                        <DashboardWalletAllTokens />
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default DashboardGraphView