
export enum Chain {
    ETH = "ETH",
    BTC = "BTC",
    SOL = "SOL",
    NONE = "NONE",
}

export interface TokenDB {
    name: string,
    symbol: string,
    amount: number,
    address: string,
    type: string
}


export enum TransactionType {
    IN = "IN",
    OUT = "OUT"
}

export interface TransactionDB {
    hash: string,
    type: TransactionType,
    fee: number,
    timestamp: string,
    chain: Chain,
    // token_address: transaction.decoded_call.params.find(param => param.name == "token").value,
    token: "string",
    amount: number,
}

export interface WalletDB {
    id?: string;
    address: string;
    chain: Chain;
    id_user: string;
    name: string;
    visibility: boolean;
    tokens?: TokenDB[];
    transactions?: TransactionDB[];
}
export interface PeriodPriceDB {
    timestamp: string;
    value: number;
}
export interface PriceDB {
    uuid: string;
    symbol: string;
    name: string;
    icon: string;
    current_price: number;
    day: PeriodPriceDB[];
    week: PeriodPriceDB[];
    month: PeriodPriceDB[];
    quarter: PeriodPriceDB[];
    year: PeriodPriceDB[];
}


export interface TokenComposition {
    name: string,
    symbol: string,
    amount: number,
    value?: number;
    icon?: string;
    price: number;
    priceChange: number;
}

