import dashoard1 from "../images/dashboard-1.svg";
import wallet from "../images/wallet.svg";
import earnImage from "../images/earn.svg";
import marketImage from "../images/market.svg";
import logoutImage from "../images/Logout.svg";
import { Link } from "react-router-dom";
import { logout } from "../utils/firebase";
import AppContext from "../AppContext";
import { Col, Container, Row } from "react-bootstrap";
import { useContext } from "react";

interface DashboardMenuProps {
    menuIndex: number;
    setMenuIndex: React.Dispatch<React.SetStateAction<number>>;
}

const DashboardMenu: React.FC<DashboardMenuProps> = ({ menuIndex, setMenuIndex }) => {

    const menu = [
        {
            title: "Dashboard",
            icon: dashoard1,
            visible: true
        },
        {
            title: "Wallet",
            icon: wallet,
            visible: true
        },
        {
            title: "Staking",
            icon: earnImage,
            visible: false
        },
        {
            title: "Market",
            icon: marketImage,
            visible: true
        }];
    const dbContext = useContext(AppContext);

    const logoutFromDashboard = () => {
        dbContext.setWallets([]);
        dbContext.setCurrentUser({});
        logout();
    }
    const rows = [];
    for (let i = 0; i < menu.length; i++) {
        // note: we are adding a key prop here to allow react to uniquely identify each
        // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
        rows.push(
            <Link onClick={() => setMenuIndex(i)} key={"dash-menu-" + i}
            aria-current="page"
            className={"dahboard-menu-tab " + (i == menuIndex ? "active-dark" : "inactive-dark")}
            hidden={!menu[i].visible} to={""}>
                <img src={menu[i].icon} loading="lazy" width="32" alt="" className={i == menuIndex ? "active" : ""} />
                <span className={"tab-title " + (i == menuIndex ? "" : "inactive")}>{menu[i].title}</span>
            </Link>
        );
    }

    return (
        <Container>
            <Row>
                <Col className="dashbaord-menu">
                    {rows}
                    <Link onClick={() => logoutFromDashboard()} key={"dash-menu-logout"}
                    aria-current="page"
                    className={"dahboard-menu-tab logout-dark"} to={""}>
                        <img src={logoutImage} loading="lazy" width="32" alt="" />
                        <span className="tab-title inactive">Log out</span>
                    </Link>
                </Col>
            </Row>
        </Container>
    );
}
export default DashboardMenu;