import React, { useContext } from "react";
import AppContext from "../../AppContext";
import {  getWalletTokensComposition, getWalletsTotalBalance } from "../../utils/crypto";
import { Col, Container, Row, Table } from "react-bootstrap";

export default function DashboardWalletAllTokens() {
    const dbContext = useContext(AppContext);
    const [tokensCount, setTokensCount] = React.useState(5);
    const [showMoreTokens, setShowMoreTokens] = React.useState(false);

    const topTokens = () => {
        let tokens = getWalletTokensComposition(dbContext);
        const totalBalance = getWalletsTotalBalance(dbContext) as number;
        tokens.sort((a, b) => b.value! - a.value!)
        if (tokensCount < tokens.length != showMoreTokens) {
            setShowMoreTokens(tokensCount < tokens.length)
        }
        return tokens.slice(0, tokensCount).map(token => {
            return (
                <tr key={token.symbol} className=" flex-column">
                    <td>
                        <Row className="justify-content-center align-items-center">
                            <Col xs={4} md={2}>
                                <img src={token.icon} style={{ maxWidth: "25px" }} />
                            </Col>
                            <Col xs={8} md={10}>
                                {token.name}
                                <br /><span className="grayed">{token.symbol}</span>
                            </Col>
                        </Row></td>
                    <td>
                        <span className="unactive">$ {Intl.NumberFormat('en-US').format(token.value!)}</span>
                        <br />{token.amount.toFixed(4)} {token.symbol}</td>
                    <td>$ {Intl.NumberFormat('en-US', { minimumFractionDigits: 6, maximumFractionDigits: 6 }).format(token.price)}</td>
                    <td>
                        <span className={`percentage-container ${token.priceChange >= 0 ? "percentage-container-positive" : ""}`}>
                            {(token.priceChange * 100).toFixed(2)} %
                        </span>
                    </td>
                    <td>
                        <span>
                            {(token.value! / totalBalance * 100).toFixed(2)} %
                        </span>
                    </td>

                </tr>
            )
        });
    }
    return (
        <Container fluid className="dashboard-container">
            <Row>
                <Col className="text-start">
                    <h5 className="dark">Wallet Assets</h5>
                </Col>
            </Row>

            <Row>
                <Col className="table-responsive text-center">
                    <Table className="dark" variant="dark">
                        <thead>
                            <tr>
                                <th scope="col">Asset</th>
                                <th scope="col">Balance</th>
                                <th scope="col">Price</th>
                                <th scope="col">Price Change</th>
                                <th scope="col">Allocation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topTokens()}
                        </tbody>
                    </Table>
                    <span className="bold text-button "
                        onClick={() => setTokensCount(tokensCount + 5)}
                        hidden={!showMoreTokens}
                    >Load More...</span>
                </Col>
            </Row>
        </Container>
    )
}