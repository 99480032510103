import DashboardWalletManagement from "./DashboardWalletManagement";
import { useContext } from "react";
import AppContext from "../../AppContext";
import { getWalletsTotalBalance } from "../../utils/crypto";
import DashboardTransaction from "./DashboardTransaction";
import DashboardWalletComposition from "./DashboardWalletComposition";
import { Col, Container, Row } from "react-bootstrap";
import DashboardMainGraph from "../dashboardGraph/DashboardMainGraph";
import loading from '../../images/loading-text.gif';


export default function DashboardWalletView() {
    const dbContext = useContext(AppContext);

    return (

        <Container fluid>
            <Row>
                <Col className="text-start">
                    <h4 className="dark">My assets</h4>
                </Col>
                <Col className="text-end">
                    {dbContext.loading.price && (
                        <img className="loading-text" src={loading} />
                    )}
                    {!dbContext.loading.price && (
                        <h4 className="dark">
                            $ {getWalletsTotalBalance(dbContext)}
                        </h4>
                    )}
                </Col>
            </Row>
            <Row id="add-wallet">
                <Col>
                    <DashboardWalletManagement />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DashboardMainGraph />
                </Col>
            </Row>
            <Row>
                <Col sm={5}>
                    <DashboardWalletComposition />
                </Col>
                <Col sm={7}>
                    <DashboardTransaction />
                </Col>
            </Row>
        </Container>
    )
}