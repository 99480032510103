import { Bar } from "react-chartjs-2";
import { getWalletsTotalBalance, getWalletsTotalBalanceByPeriod } from "../../utils/crypto";
import AppContext from "../../AppContext";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { calculateLabels } from "./DashboardHomeGraph";
import { Chain } from "../../utils/Types";
import loading from '../../images/loading-text.gif';
import loadingGraph from '../../images/loading-graph.gif';


export default function DashboardMainGraphProps() {

    const dbContext = useContext(AppContext);
    const [timePeriod, setTimePeriod] = React.useState("day");

    const columns = () => {
        switch (timePeriod) {
            case "day":
                return 12;
            case "week":
                return 7;
            case "month":
                return 15;
            case "quarter":
                return 18;
            case "year":
                return 12;
            default:
                return 0;
        }
    }

    const data = {
        labels: calculateLabels(timePeriod),
        datasets: [
            {
                label: 'BTC',
                data: getWalletsTotalBalanceByPeriod(dbContext, Chain.BTC, timePeriod, columns()),
                backgroundColor: '#ff69b4',
                borderRadius: 15,
            },
            {
                label: 'ETH',
                data: getWalletsTotalBalanceByPeriod(dbContext, Chain.ETH, timePeriod, columns()),
                backgroundColor: '#ab4cfe',
                borderRadius: 15,
            },
            {
                label: 'SOL',
                data: getWalletsTotalBalanceByPeriod(dbContext, Chain.SOL, timePeriod, columns()),
                backgroundColor: '#00cc66',
                borderRadius: 15,
            },
            // Aggiungi altri dataset se necessario
        ],
    };

    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                stacked: true,
                ticks: {
                    color: "white"
                }
            },
            x: {
                stacked: true,
                ticks: {
                    color: "white"
                }
            },
        },
        elements: {
            bar: {
                barPercentage: 0.2,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                        }
                        return label;
                    }
                }
            },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    generateLabels: (chart: any) => {
                        const datasets = chart.data.datasets;
                        return datasets.map((dataset: any) => ({
                            text: `${dataset.label}`,
                            fontColor: 'white',
                            color: "#fff",
                            fillStyle: dataset.backgroundColor,
                        }))
                    },
                }
            }
        },
    };

    return (
        <>
            <Container fluid className="dashboard-container">
                {/* <Row>
                    <Col className="text-start">
                        <h6 >Wallet</h6>
                    </Col>
                    <Col className="text-end">
                        <h6 className="text-button" onClick={() => goToWalletPage()}>+ Add Wallet</h6>
                    </Col>
                </Row> */}
                <Row className="small-top-bottom-padding">
                    <Col className="text-start only-mobile">
                        {dbContext.loading.price && (
                            <img className="loading-text" src={loading} />
                        )}
                        {!dbContext.loading.price && (
                            <h6 >$ {getWalletsTotalBalance(dbContext)}</h6>
                        )}
                    </Col>
                </Row>
                <Row className="small-top-bottom-padding align-items-center">
                    <Col md={7} className="text-start only-desktop">
                        {dbContext.loading.price && (
                            <img className="loading-text" src={loading} />
                        )}
                        {!dbContext.loading.price && (
                            <h6 >$ {getWalletsTotalBalance(dbContext)}</h6>
                        )}
                    </Col>
                    <Col md={5} className="text-end">
                        <Row className="row-timeperiod-selector">
                            <Col className="justify-content-center align-items-center" style={{ padding: 0 }}>
                                <h6 onClick={() => setTimePeriod("day")}
                                    className={`timeperiod-selector ${timePeriod == "day" ? "timeperiod-active" : ""} dark`}>24H</h6>
                            </Col>
                            <Col >
                                <h6 onClick={() => setTimePeriod("week")}
                                    className={`timeperiod-selector ${timePeriod == "week" ? "timeperiod-active" : ""} dark`}>7D</h6>
                            </Col>
                            <Col >
                                <h6 onClick={() => setTimePeriod("month")}
                                    className={`timeperiod-selector ${timePeriod == "month" ? "timeperiod-active" : ""} dark`}>30D</h6>
                            </Col>
                            <Col >
                                <h6 onClick={() => setTimePeriod("quarter")}
                                    className={`timeperiod-selector ${timePeriod == "quarter" ? "timeperiod-active" : ""} dark`}>90D</h6>
                            </Col>
                            <Col >
                                <h6 onClick={() => setTimePeriod("year")}
                                    className={`timeperiod-selector ${timePeriod == "year" ? "timeperiod-active" : ""} dark`}>1Y</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <div className="graph-container">
                        <Bar options={options} data={data} width={"100%"} style={{ height: "400px" }} />
                        {dbContext.loading.price && (
                            <div className="laoding-graph-container">
                                <img src={loadingGraph} className="loading-graph" />
                            </div>
                        )}
                    </div>
                </Row>
            </Container>
        </>
    )
}