import { useContext } from "react";
import AppContext from "../../AppContext";
import DashboardMarketTopCoins from "./DashboardMarketTopCoins";
import DashboardMarketNews from "./DashboardMarketNews";
import { Col, Container, Row } from "react-bootstrap";
import DashboardMarketStats from "./DashboardMarketStats";

export default function DashboardMarketView() {
    const dbContext = useContext(AppContext);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="text-start">
                        <h4 className="dark">Market</h4>
                    </Col>
                </Row>
                <Row>
                    <DashboardMarketStats />
                </Row>
                <Row>
                    <DashboardMarketTopCoins />
                </Row>
                <Row>
                    <DashboardMarketNews />
                </Row>
            </Container >

        </>
    )
}