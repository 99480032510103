import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";
import {
  getFirestore,
  collection,
  query, getDocs, doc, addDoc, deleteDoc, Firestore, FirestoreSettings, DocumentSnapshot, QuerySnapshot, updateDoc
} from "firebase/firestore";
import { getTokensPrice, getEthereumBalance, getEthereumTransactions, getBitcoinBalanceWithTransaction, getLatestNews, getSolanaBalance, getSolanaTransactions } from "./crypto";
import { Chain, WalletDB } from "./Types";
import { Context } from "vm";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyASMbuV-GmqSwdM426zyu9YZgthZurwDoE",
  authDomain: "umbrella-d96b1.firebaseapp.com",
  projectId: "umbrella-d96b1",
  storageBucket: "umbrella-d96b1.appspot.com",
  messagingSenderId: "530233945347",
  appId: "1:530233945347:web:81fe0a43f78278774a4d36"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


const db: Firestore = getFirestore(app);
const settings: FirestoreSettings = {
  ignoreUndefinedProperties: true
};
//setFirestoreSettings(db, settings);

// const googleProvider = new GoogleAuthProvider();

// const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "users"), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    console.log("successfully logged in");
    return true;
  } catch (err) {
    console.error(err);
    if (err instanceof Error)
      alert(err.message);
    return false;
  }
};

const logout = () => {
  signOut(auth);
};

const getWallets = async (uid: string, dbContext: any) => {
  try {
    var walletQuery = collection(db, 'Wallet');
    var result: QuerySnapshot = await getDocs(walletQuery);
    // await dbContext.setUsers(
    //   result.docs.map((user: DocumentSnapshot) => {
    //     const ret = Utente.utenteFromSnapshot(user);
    //     return ret;
    //   })
    // );
    const wallets: WalletDB[] = result.docs.map((wallet: DocumentSnapshot) => {
      const data = wallet.data();
      return {
        id: data!.id,
        address: data!.address,
        chain: data!.chatin,
        id_user: data!.id_user,
        name: data!.name,
        visibility: data!.visibility,
      };
    });
    dbContext.setWallets(wallets);
    return wallets;
  } catch (err) {
    console.error(err);
    alert("An error occured while fetching user wallets");
    return {};
  }
}

const addNewWallet = async (name: string, address: string, type: Chain, dbContext: Context) => {
  try {
    const newWallet: WalletDB = {
      id_user: auth!.currentUser!.uid,
      name: name,
      address: address,
      visibility: true,
      chain: type
    };
    const res = await addDoc(collection(db, "Wallet"), newWallet);
    newWallet.id = res.id;
    const oldWallets = dbContext.wallets;
    oldWallets.unshift(newWallet)
    dbContext.setWallets(oldWallets);
  } catch (err) {
    console.error(err);
    if (err instanceof Error)
      alert(err.message);
  }
};
const deleteWallet = async (id: string, dbContext: Context) => {
  try {
    const docRef = doc(db, "Wallet", id);
    deleteDoc(docRef);
    dbContext.setWallets(dbContext.wallets.filter((wallet: WalletDB) => wallet.id != id));
  } catch (err) {
    console.error(err);
    if (err instanceof Error)
      alert(err.message);
  }
};

const updateWalletVisibility = async (id: string, visibility: boolean) => {
  try {
    const docRef = doc(db, "Wallet", id);
    updateDoc(docRef, {
      visibility: visibility
    });
  } catch (err) {
    console.error(err);
    if (err instanceof Error)
      alert(err.message);
  }
}
// const registerWithEmailAndPassword = async (name, email, password) => {
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     await addDoc(collection(db, "users"), {
//       uid: user.uid,
//       name,
//       authProvider: "local",
//       email,
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

/*
const sendPasswordReset = async (email: string) => {
  return sendAuthMail(email, "resetPassword")
}
const sendVerifyMail = async (email: string) => {
  return sendAuthMail(email, "verifyEmail")
}
const sendAuthMail = async (email: string, mode: string) => {
  try {
    const res = await axios.get(env.SEND_AUTH_MAIL + `?mode=${mode}&userEmail=${email}`, {
      headers: { "Access-Control-Allow-Origin": "*" }
    });
    //await sendPasswordResetEmail(auth, email);
    alert("Mail inviata!");
  } catch (err: any) {
    console.error(err);
    alert(err.message + " while sending verification email");
  }
};

const createAuthUser = async (user: User, body: Utente) => {
  try {
    const idToken = await user.getIdToken(true);
    const res = await axios.post(env.SAVE_USER, body.serializeUtente(), {
      headers: {
        'Authorization': 'Bearer ' + idToken
      }
    });
    //await sendPasswordResetEmail(auth, email);
    console.log("Utente creato con successo");
    return res.data;
  } catch (err: any) {
    console.error(err);
    alert(err.response.data.message + " while creating user authentication");
    return false
  }
};


const getUserById = async (id: string) => {
  try {
    const docRef = doc(db, "Utenti", id);
    const userDB = await getDoc(docRef);
    return Utente.utenteFromSnapshot(userDB);
  } catch (err) {
    console.error(err);
    alert("An error occured while fetching use info");
    return {};
  }
}

const saveUtente = async (userData: any, dbContext: Context, user: User) => {
  try {
    const esistente: Boolean = userData && userData.id != undefined && userData.id != "";

    const idToken = await user.getIdToken(true);
    const res: AxiosResponse = await axios.post(env.SAVE_USER, userData, {
      headers: {
        'Authorization': 'Bearer ' + idToken
      }
    });
    //await sendPasswordResetEmail(auth, email);
    console.log("Utente creato con successo");
    const savedUtente: Utente = Utente.deserializeUtente(res.data);

    const newUsers: Utente[] = dbContext.users;
    if (esistente) {
      const index = newUsers.findIndex(((obj: Utente) => obj.id === userData.id));
      newUsers[index] = savedUtente;
    } else {
      newUsers.unshift(savedUtente);
    }
    dbContext.setClubs(newUsers);
    return true;
  } catch (err) {
    console.error(err);
    alert("An error occured while saving user");
    return false;
  }
}

const getClubById = async (id: string) => {
  try {
    const docRef = doc(db, "Club", id);
    const userDB = await getDoc(docRef);
    return Club.clubFromSnapshot(userDB);
  } catch (err) {
    console.error(err);
    alert("An error occured while fetching use info");
    return {};
  }
}

const saveClub = async (clubData: Club, dbContext: Context) => {
  try {
    const newClubs = dbContext.clubs;
    const index = newClubs.findIndex(((obj: Club) => obj.id === clubData.id));
    const docToBeSaved = JSON.parse(JSON.stringify(clubData));
    // docToBeSaved.promotions = docToBeSaved.promotions.map((prom: Promotion) => {
    //   if (prom.id == undefined) {
    //     prom.id = uuidv4();
    //   }
    //   prom.unit_price = prom.unit_price * 100;
    //   prom.insertion_date = dateToMillisecond(prom.insertion_date as string);
    //   prom.ending_date = dateToMillisecond(prom.ending_date as string);
    //   return prom;
    // });
    if (index < 0) {
      const collectionRef = collection(db, "Club");
      const res = await addDoc(collectionRef, docToBeSaved);
      clubData.id = res.id;
      newClubs.push(clubData);
      dbContext.setClubs(newClubs);
      return clubData;
    } else {
      const docRef = doc(db, "Club", docToBeSaved.id);
      const res = await setDoc(docRef, docToBeSaved);
      newClubs[index] = clubData;
      dbContext.setClubs(newClubs);
    }
    return true;
  } catch (err) {
    console.error(err);
    alert("An error occured while saving club");
    return false;
  }
}

const savePromotion = async (promotion: any, idClub: string, dbContext: Context) => {
  try {
    const esistente: Boolean = promotion && promotion.id != undefined && promotion.id != "";
    if (!esistente) promotion.id = uuidv4();
    const indexClub: number = dbContext.clubs.findIndex((club: Club) => club.id == idClub);
    const club: Club = dbContext.clubs[indexClub];
    if (!esistente) {
      club.promotions.unshift(Promotion.deserializePromotion(promotion));
    } else {
      const indexToUpdate = club.promotions.findIndex(prom => prom.id === promotion.id);
      club.promotions[indexToUpdate] = Promotion.deserializePromotion(promotion);
    }
    const docRef = doc(db, "Club", idClub);
    const res = await setDoc(docRef, JSON.parse(JSON.stringify(club.serializeClub())));
    const clubs: Club[] = dbContext.clubs;
    clubs[indexClub] = club;
    dbContext.setClubs(clubs);

    return true;
  } catch (err) {
    console.error(err);
    alert("An error occured while saving club");
    return false;
  }
}
const deletePromotion = async (promotion: any, idClub: string, dbContext: Context) => {
  try {
    const indexClub: number = dbContext.clubs.findIndex((club: Club) => club.id == idClub);
    const club: Club = dbContext.clubs[indexClub];
    club.promotions = club.promotions.filter((prom: Promotion) => prom.id != promotion.id);
    const docRef = doc(db, "Club", idClub);
    const res = await setDoc(docRef, JSON.parse(JSON.stringify(club.serializeClub())));
    const clubs: Club[] = dbContext.clubs;
    clubs[indexClub] = club;
    dbContext.setClubs(clubs);

    return true;
  } catch (err) {
    console.error(err);
    alert("An error occured while saving club");
    return false;
  }
}
*/
const loadDbData = async (dbContext: Context) => {
  try {
    var collectionQuery = collection(db, 'Wallet');
    var result = await getDocs(collectionQuery);
    const wallets: WalletDB[] = [];
    for (let i = 0; i < result.docs.length; i++) {
      const dbWallet = result.docs[i];
      const wall: WalletDB = dbWallet.data() as WalletDB;
      wall.id = dbWallet.id;
      switch (wall.chain) {
        case Chain.ETH:
          wall.tokens = await getEthereumBalance(wall.address) || [];
          wall.transactions = await getEthereumTransactions(wall.address) || [];
          break;
        case Chain.BTC:
          const bitcoinBalance: any = await getBitcoinBalanceWithTransaction(wall.address) || [];
          wall.tokens = bitcoinBalance.tokens || [];
          wall.transactions = bitcoinBalance.transactions || [];
          break;
        case Chain.SOL:
          wall.tokens = await getSolanaBalance(wall.address) || [];
          wall.transactions = [];//await getSolanaTransactions(wall.address) || [];
          break;
        default:
          break;
      }
      wallets.push(wall);
    };
    const walletLoading = dbContext.loading;
    walletLoading.wallet = false;
    dbContext.setLoading(walletLoading);
    dbContext.setWallets(wallets);
    getTokensPrice(wallets, dbContext);
    getLatestNews(dbContext);
    console.log("LOADED INITIAL DATA")
  } catch (err) {
    console.error(err);
    alert("An error occured while fetching user data");
  }
}

export {
  auth,
  db,
  // signInWithGoogle,
  logInWithEmailAndPassword,
  logout,
  addNewWallet,
  deleteWallet,
  updateWalletVisibility,
  // registerWithEmailAndPassword,
  // sendPasswordReset,
  // sendVerifyMail,
  loadDbData,
  // deletePromotion,
  // savePromotion,
  // saveClub,
  // saveUtente,
  // createAuthUser,
  // getUserById*/
};
