
import logoDark from '../images/logo-dark.svg';
import { Link } from 'react-router-dom';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';

export default function Footer() {
    return (
        <>
            <Container className='menu-container top-bottom-padding'>
                <Col>
                    <Navbar className="navbar dark navbar-dark" >
                        <Container>
                            <Navbar.Brand href="/">
                                <img src={logoDark} loading="lazy" alt="" className="logo-light-website" />
                            </Navbar.Brand>
                            <Nav className="justify-content-center align-items-center menu footer">
                                <Nav.Link href="/" className='nav-link-dark'>Home</Nav.Link>
                                <Nav.Link href="/about" className='nav-link-dark'>About</Nav.Link>
                                <Nav.Link href="/features" className='nav-link-dark'>Features</Nav.Link>
                                <Nav.Link href="/contact" className='nav-link-dark'>Contact</Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar>
                    <Row className='top-bottom-padding'>
                        <Col className='text-right'>
                            <div className='text text-end'>
                                © Umbrella 2023. All rights reserved.
                            </div>
                        </Col>
                        <Col className='text-start'>
                            <Link to="/privacy" className='dark text'>
                                Privacy and Cookie Policy
                            </Link>
                        </Col>
                    </Row>
                </Col>

            </Container>
        </>
    );
}