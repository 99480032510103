import svg1 from '../images/01.svg';
import svg2 from '../images/02.svg';
import svg3 from '../images/03.svg';
import svg4 from '../images/04.svg';
import svg5 from '../images/05.svg';
import svg6 from '../images/06.svg';
import dashDark from '../images/dashdark.png';
import marketDark from '../images/marketdark.png';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { Col, Container, Row, Button } from 'react-bootstrap';
import TopFooter from './TopFooter';


export default function Home() {
    return (
        <>
            <Container fluid className='container-home-1'>
                <Header />
                <Container>
                    <Row className='top-padding'>
                        <Col sm={12} md={6}>
                            <h3 className='dark outline-h3-dark'>Tailored Solutions</h3>
                            <h1 className="dark">Real-Time Dashboards</h1>
                            <div className="dark text top-bottom-padding">Unlocking the Future of Digital Asset Management<br />Your Assets, Your Terms: Ultimate Control</div>
                            <Button className="button dark-button"
                                href="/contact"
                            >Learn More</Button>
                        </Col>
                        <Col sm={12} md={6}>
                            <img src={dashDark} loading="lazy" alt="" />
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className='text-center top-bottom-padding'>
                        <h2 className="dark">OUR GUIDING PRINCIPLES</h2>
                    </Row>
                    <Row className='top-bottom-padding'>
                        <Col sm={12} md={4} className='text-center' fluid>
                            <img src={svg1} loading="lazy" alt="" className="image-10" />
                            <h3 className="dark top-bottom-padding">CLIENT-CENTRIC CONTROL</h3>
                            <div className="dark text">Take the reins of your digital assets. Umbrella’s dashboard lets you manage your investments on your terms, offering direct oversight and real-time insights.</div>

                        </Col>
                        <Col sm={12} md={4} className='text-center' fluid>
                            <img src={svg2} loading="lazy" alt="" className="image-11" />
                            <h3 className="dark top-bottom-padding">ADAPTIVE ARCHITECTURE</h3>
                            <div className="dark text">We understand that every client is unique. That's why our platform offers modular solutions to perfectly align with the specific requirements and workflows of each client, from small private banks to family offices and large institutions.</div>

                        </Col>
                        <Col sm={12} md={4} className='text-center' fluid>
                            <img src={svg3} loading="lazy" alt="" className="image-12" />
                            <h3 className="dark top-bottom-padding">INFORMED DECISION-MAKING</h3>
                            <div className="dark text">Our expert-level dashboard feeds you real-time data to keep you ahead of the curve in the digital asset arena. Beyond metrics, we offer consultancy services and introduce you to key players in the crypto ecosystem, enriching both your insights and networks.</div>

                        </Col>
                    </Row>
                </Container>
                <Container>
                    <img src={marketDark} loading="lazy" />
                </Container>

            </Container>
            <Container fluid className='container-home-2 text-center'>
                <h2 className="dark top-padding">Why Choose Umbrella?</h2>
                <Row className='top-bottom-padding'>
                    <Col sm={12} md={4} className='text-center'>
                        <img src={svg4} loading="lazy" alt="" className="image-10" />
                        <h3 className="dark top-bottom-padding">Unparalleled Autonomy</h3>
                        <div className="dark text">Take complete control of your digital assets. Our user-friendly, state-of-the-art dashboard offers you the freedom to directly manage your investments, on-chain assets, and digital assets</div>

                    </Col>
                    <Col sm={12} md={4} className='text-center'>
                        <img src={svg5} loading="lazy" alt="" className="image-11" />
                        <h3 className="dark top-bottom-padding">Tailored Solutions</h3>
                        <div className="dark text">We cater to high-value clients, offering a level of customization that&#x27;s missing from most &#x27;one-size-fits-all&#x27; platforms. Whether you&#x27;re an ultra-high net worth individual, small private bank, a family office, or an institutional investor, we&#x27;ve got you covered.</div>

                    </Col>
                    <Col sm={12} md={4} className='text-center'>
                        <img src={svg6} loading="lazy" alt="" className="image-12" />
                        <h3 className="dark top-bottom-padding">Transparency and Security</h3>
                        <div className="dark text">With our platform, transparency and ownership are at the forefront. We simplify your experience in the digital asset space while building trust through clear visibility and secure handling.</div>

                    </Col>
                </Row>
            </Container>
            <TopFooter />
            <Footer />
        </>
    );
}