
import infoImage from "../../images/info.svg";
import group183Image from "../../images/Group-183.svg";
import recentTransactions01 from "../../images/Recent-Transactions01.svg";
import recentTransactions02 from "../../images/Recent-Transactions02.svg";
import recentTransactions03 from "../../images/Recent-Transactions03.svg";
import recentTransactions04 from "../../images/Recent-Transactions04.svg";
import recentTransactions05 from "../../images/Recent-Transactions05.svg";
import recentTransactions06 from "../../images/Recent-Transactions06.svg";
import { Link } from "react-router-dom";
import AppContext from "../../AppContext";
import React, { useContext } from "react";
import { getExternalUrl } from "../../utils/crypto";
import moment from "moment";
import { Col, Container, Row, Table } from "react-bootstrap";
import { TransactionDB, WalletDB } from "../../utils/Types";

export default function DashboardTransaction() {
    const [transactionCount, setTransactionCount] = React.useState(10);
    const [showMoreTransaction, setShowMoreTransaction] = React.useState(false);
    const dbContext = useContext(AppContext);

    const transactions = () => {
        let totalTran: TransactionDB[] = [];
        dbContext.wallets.filter((wallet: WalletDB) => wallet.visibility).forEach((wallet: WalletDB) => {
            if (!!wallet.transactions)
                totalTran = totalTran.concat(wallet.transactions);
        });
        totalTran.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        if (transactionCount < totalTran.length != showMoreTransaction) {
            setShowMoreTransaction(transactionCount < totalTran.length)
        }
        return totalTran.slice(0, transactionCount).map(transaction => {
            return (
                <tr key={transaction.hash} className="flex-column">
                    <td>
                        {transaction.type == "IN" ? "Deposit" : "Withdrawl"}<br />
                        <span className="unactive">{moment(transaction.timestamp).format('DD/MM/YYYY HH:mm')}</span>
                    </td>
                    <td>{transaction.token}
                        <br />{transaction.amount.toFixed(8)}
                    </td>
                    <td>{transaction.fee.toFixed(8) + " " + transaction.chain}</td>
                    <td><Link to={getExternalUrl(transaction.chain, "transaction", transaction.hash)} target="_blank" className="no-link">On Chain <b>&rarr;</b></Link></td>
                </tr>
            )
        });

    }
    return (
        <Container fluid className="dashboard-container">
            <Row>
                <Col className="text-start">
                    <h5 className="dark">Recent Transactions</h5>
                </Col>
            </Row>
            <Row>
                <Col className="text-center table-responsive">
                    <Table className="dark" variant="dark">
                        <thead>
                            <tr>
                                <th scope="col" className="col-xs-12">Typology</th>
                                <th scope="col" className="col-xs-12">Details</th>
                                <th scope="col" className="col-xs-12">Fee</th>
                                <th scope="col" className="col-xs-12"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions()}
                        </tbody>
                    </Table>
                    <span className="bold text-button "
                        onClick={() => setTransactionCount(transactionCount + 10)}
                        hidden={!showMoreTransaction}
                    >Load More...</span>

                </Col>
            </Row>
        </Container>
        // <div className="recent-t-2">
        //  <div
        //     style={{
        //         opacity: 0,
        //         WebkitTransform: 'translate3d(0, 0, -1px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
        //         MozTransform: 'translate3d(0, 0, -1px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
        //         msTransform: 'translate3d(0, 0, -1px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
        //         transform: 'translate3d(0, 0, -1px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
        //         transformStyle: 'preserve-3d',
        //     }}
        //     className="popup-info-transaction"
        // >                                <div className="title-h2 padding-10"><img src={infoImage} loading="lazy" width="24" height="24" alt="" className="info-icon" />
        //         <h1 className="h2-app">Recent Transactions</h1>
        //     </div>
        //     <p className="paragraph-2">View a list of the most recent activities related to your digital asset account.</p>
        //     <h1 data-w-id="8b61e7c0-2b1d-df7f-b77d-18a01a6911e0" className="text-button-2">Close</h1>
        // </div> 
        // <div className="title-with-button">
        //     <div className="title-h2">
        //         <h1 className="h2-app">Recent Transactions</h1><img src={infoImage} loading="lazy" width="24" height="24" alt="" data-w-id="8b61e7c0-2b1d-df7f-b77d-18a01a6911e6" className="info-icon" />
        //     </div>
        // </div>
        // <table className="table table-dark table-hover">
        //     <thead>
        //         <tr>
        //             <th scope="col">Typology</th>
        //             <th scope="col">Details</th>
        //             <th scope="col">Fee</th>
        //             <th scope="col"></th>
        //         </tr>
        //     </thead>
        //     <tbody>
        //         {transactions()}
        //     </tbody>
        // </table>
        // <img src={group183Image} loading="lazy" alt="" className="image-table" />
        //         <img src={recentTransactions01} loading="lazy" alt="" className="image-table" />
        //         <img src={recentTransactions02} loading="lazy" alt="" className="image-table" />
        //         <img src={recentTransactions03} loading="lazy" alt="" className="image-table" />
        //         <img src={recentTransactions04} loading="lazy" alt="" className="image-table" />
        //         <img src={recentTransactions05} loading="lazy" alt="" className="image-table" />
        //         <img src={recentTransactions06} loading="lazy" alt="" className="image-table" /> 
        //    </div >
    )
}