import Footer from "../components/Footer";
import Header from "../components/Header";
import { Col, Container, Row } from "react-bootstrap";
import TopFooter from "./TopFooter";


export default function Privacy() {
    return (
        <>
            <Container fluid className='container-features-1'>
                <Header />
                <Container>
                    <Row className='top-padding bottom-padding'>
                        <Col className="text-center">
                            <h1 className="dark">
                                PRIVACY AND COOKIE POLICY
                            </h1>
                            <div className="dark text top-bottom-padding">
                                Protecting Your Information: Our Privacy Commitment
                            </div>


                            <br />At Umbrella, we are committed to protecting the privacy and security of our users. This Privacy and Cookie Policy is designed to inform you about the data we collect, why we collect it, and how we use it. By using our services, you agree to the practices described in this policy.

                            ‍<br /><h3 className="dark">Information Collection</h3>
                            ‍<br /><div className="text dark">
                                We collect various types of information for different purposes to provide and improve our services to you. This may include personal information such as your name, email address, and other data provided when you interact with our website.
                            </div>
                            <br /> <h3 className="dark"> ‍How We Use Your Information</h3>
                            <br />  ‍<div className="text dark">
                                ‍The information we collect is used to personalize your experience, improve our services, and communicate with you. We may also use the data for analytics, marketing, and to ensure the security of our website.
                            </div>

                            <br />   ‍<h3 className="dark">Cookie Usage</h3>
                            <br />    ‍<div className="text dark">
                                ‍Like many websites, we use cookies and similar technologies to collect information for various purposes. Cookies are small files stored on your device that help us improve your browsing experience. You can manage or disable these cookies in your browser settings, but this might affect the functionality of the site.
                            </div>

                            <br />   ‍<h3 className="dark">Data Sharing</h3>
                            <br />     ‍<div className="text dark">
                                ‍We do not sell or share your personal information with third parties for their marketing purposes. However, we may share data with trusted partners for specific purposes such as analytics or service improvement. We ensure these partners adhere to data protection regulations.
                            </div>

                            <br />    <h3 className="dark"> ‍Data Security</h3>
                            <br />    ‍<div className="text dark">
                                ‍We take the security of your data seriously. We employ appropriate measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
                            </div>

                            <br />    ‍<h3 className="dark">Changes to this Policy</h3>
                            <br />    ‍<div className="text dark">
                                ‍We reserve the right to update or make changes to this Privacy and Cookie Policy. Any revisions will be posted on this page with an updated date. By continuing to use our services after any modifications, you agree to the updated policy.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <TopFooter showFeatures={false} />
            <Footer />

        </>
    )
}