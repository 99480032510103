import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import AppContext from './AppContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Error404 from './website/Error404';
import Home from './website/Home';
import Privacy from './website/Privacy';
import Contact from './website/Contact';
import Features from './website/Features';
import About from './website/About';
import Dashboard from './Dashboard';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi1/react';
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi1'

import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { mainnet } from 'viem/chains'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const projectId = 'db1ebc9778bd8f191bdac7c1ca7102ec';
// 2. Create wagmiConfig
const { chains, publicClient } = configureChains(
  [mainnet],
  [walletConnectProvider({ projectId }), publicProvider()]
)

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } })
  ],
  publicClient
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

function App() {
  useEffect(() => {
    document.body.classList.add('body');
  }, []);

  const [wallets, setWallets] = React.useState([]);
  const [prices, setPrices] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState({});
  const [marketCapData, setMarketCapData] = React.useState({
    total_market_cap: 0,
    total_market_cap_change: 0,
    trading_volume: 0,
    btc_market_cap: 0
  });
  const [topRankingCoins, setTopRankingCoins] = React.useState([]);
  const [news, setNews] = React.useState([]);
  const [loading, setLoading] = React.useState({
    wallet: true,
    price: true,
    market: true,
    news: true,
    topCoins: true,
  });

  return (
    <WagmiConfig config={wagmiConfig}>
      <AppContext.Provider value={{
        wallets, setWallets,
        prices, setPrices,
        currentUser, setCurrentUser,
        marketCapData, setMarketCapData,
        topRankingCoins, setTopRankingCoins,
        news, setNews,
        loading, setLoading,
      }}>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="features" element={<Features />} />
            <Route path="contact" element={<Contact />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider >
    </WagmiConfig>
  );
}

export default App;
