import { Button, Col, Container, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import Header from "../components/Header";
import mail from '../images/mail.svg';
import TopFooter from "./TopFooter";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const EMAIL_JS_API_KEY = "CARydh37yJ83TYE3F"
const REACT_APP_SITE_KEY = "6LcyiVkpAAAAABgXl6AZ8vd-jn4xhmD7Zujttfst";


export default function Contact() {
    const recaptcha = React.useRef<ReCAPTCHA>(null);
    const [form, setForm] = React.useState({
        name: "",
        lastName: "",
        mail: "",
        message: "",
        response: ""
    });

    const handleInputChange = <T extends HTMLInputElement | HTMLTextAreaElement>(event: React.ChangeEvent<T>) => {
        const target = event?.target;
        // const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setForm(prevForm => ({ ...prevForm, [name]: target.value }));
    }


    const sendMail = async () => {
        const captchaValue = recaptcha.current?.getValue();
        if (!captchaValue) {
            alert("Please verify the reCAPTCHA!");
            setForm(prevForm => ({ ...prevForm, response: "Please verify the reCAPTCHA!" }));
        } else {
            const response = await emailjs.send("service_wflhuog", "template_420g3no", {
                from_name: form.name,
                from_last_name: form.lastName,
                from_mail: form.mail,
                message: form.message,
            }, EMAIL_JS_API_KEY
            );
            const res = response.status != 200 ?
                "The message can't be sent, please try again in a while." :
                "Thank you for reaching out to us. We will get back to you as soon as possible.";
            setForm(prevForm => ({ ...prevForm, response: res }));
        }

    }

    return (
        <>
            <Container fluid className='container-contact-1'>
                <Header />
                <Container>
                    <Row className='top-padding'>
                        <Col sm={12} md={6}>
                            <h1 className="dark">
                                SCHEDULE YOUR CONSULTATION TODAY
                            </h1>
                            <h3 className='dark outline-h3-dark'>
                                Ready to Take Control of Your Digital Assets?
                            </h3>
                            <div className="dark text top-bottom-padding">
                                Unlock a world of features designed to put you in the driver's seat. Seamless integration, real-time insights, and more await. Contact us for more information.
                            </div>
                            <Row className='align-items-center small-bottom-padding'>
                                <Col xs={2}><img src={mail} loading="lazy" alt="" className='checkmark' /></Col>
                                <Col xs={10} className='text-start'>
                                    <Link to={"mailto:info@umbrelladigital.tech"} className='text bold'>
                                        info@umbrelladigital.tech
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={6} className="top-bottom-padding">
                            <div className="contact-form">
                                <form>
                                    <Row className="name-input">
                                        <Col xs={12} md={6}>
                                            <input type="text" max="256" name="name" value={form.name} data-name="First Name" placeholder="First Name" id="First-Name" required={true} onChange={(e) => handleInputChange(e)} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <input type="text" max="256" name="lastName" value={form.lastName} data-name="Last Name" placeholder="Last Name" id="Last-Name" onChange={(e) => handleInputChange(e)} />
                                        </Col>
                                    </Row>
                                    <input type="email" max="256" name="mail" value={form.mail} data-name="Your Email" placeholder="Your Email" id="Your-Email" required={true} onChange={(e) => handleInputChange(e)} />
                                    <textarea required={true} placeholder="Message" id="Message" name="message" value={form.message} data-name="Message" className="textarea w-input" onChange={(e) => handleInputChange(e)}>
                                    </textarea>
                                    <ReCAPTCHA ref={recaptcha} sitekey={REACT_APP_SITE_KEY} />
                                    <Button className="button dark-outline-button" onClick={() => sendMail()}
                                    >Submit</Button>
                                    <span style={{ color: "red", fontWeight: "800" }}>{form.response}</span>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <TopFooter showFeatures={false} />
            <Footer />
        </>
    )
}