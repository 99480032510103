import { useContext } from "react";
import AppContext from "../../AppContext";
import { Line } from "react-chartjs-2";
import { Col, Container, Row, Table } from "react-bootstrap";

export default function DashboardMarketTopCoins() {
    const dbContext = useContext(AppContext);

    const topRankingTokens = () => {
        return dbContext.topRankingCoins.map((token: any) => {
            const data: any = {
                labels: Array(token.sparkline.length).fill(""),
                datasets: [
                    {
                        data: token.sparkline.filter((spark: any) => spark != null).map((spark: any) => Number(spark)),
                        borderColor: Number(token.change) > 0 ? "#00cc66" : "#ff69b4",
                        fill: false,
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                    },
                ],
            };
            const options = {
                responsive: true,
                scales: {
                    x: {
                        display: false,
                    },
                    y: {
                        display: false,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context: any) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    },
                },
                interaction: {
                    intersect: false,
                },
            };

            return (
                <tr key={token.symbol}>
                    <td><img src={token.iconUrl} style={{ maxWidth: "25px" }} /></td>
                    <td>{token.name}<br /><span className="unactive">{token.symbol}</span></td>
                    <td>$ {Intl.NumberFormat('en-US').format(Number(token.price))}</td>
                    <td className={Number(token.change) > 0 ? "positive" : "negative"}>{token.change}%</td>
                    <td>$ {Intl.NumberFormat('en-US').format(Number(token['24hVolume']))}</td>
                    <td>$ {Intl.NumberFormat('en-US').format(Number(token.marketCap))}</td>
                    <td style={{ maxHeight: "80px" }}>
                        <Line options={options} data={data} />
                    </td>
                </tr>
            )
        });
    }
    return (
        <Container fluid className="dashboard-container">
            <Row>
                <Col className="text-start small-bottom-padding">
                    <h5 className="dark">Single Asset</h5>
                    <br /><span className="text dark">Single top performing digital assets in the last 24h</span>
                </Col>
            </Row>
            <Row>
                <Col className="table-responsive">
                    <Table className="dark" variant="dark">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Asset</th>
                                <th scope="col">Price</th>
                                <th scope="col">Variation (24h)</th>
                                <th scope="col">Volume (24h)</th>
                                <th scope="col">Capitalization</th>
                                <th scope="col">Trend</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topRankingTokens()}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>

    );
}