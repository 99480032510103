import { getWalletsTotalBalance, getWalletsTotalBalanceByPeriod } from "../../utils/crypto";
import AppContext from "../../AppContext";
import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { ScriptableContext } from "chart.js";
import loading from '../../images/loading-text.gif';
import loadingGraph from '../../images/loading-graph.gif';


interface DashboardHomeGraphProps {
    goToWalletPage: any;
}

export function calculateLabels(timePeriod: string) {
    const now = new Date();
    const res = [];
    switch (timePeriod) {
        case "day":
            for (let i = 0; i < 24; i += 2) {
                res.push(new Date(now.getTime() - i * 60 * 60 * 1000));
            }
            break;
        case "week":
            for (let i = 0; i < 7; i++) {
                res.push(new Date(now.getTime() - i * 24 * 60 * 60 * 1000));
            }
            break;
        case "month":
            for (let i = 0; i < 30; i += 2) {
                res.push(new Date(now.getTime() - i * 24 * 60 * 60 * 1000));
            }
            break;
        case "quarter":
            for (let i = 0; i < 90; i += 5) {
                res.push(new Date(now.getTime() - i * 24 * 60 * 60 * 1000));
            }
            break;
        case "year":
            for (let i = 0; i < 12; i++) {
                const label = new Date();
                label.setMonth(label.getMonth() - i);
                res.push(label);
            }
            break;
        default:
            return [];
    }
    return res.reverse().map(date => {
        if (timePeriod == "day")
            return date.toISOString().slice(0, 16).replace(/-/g, "/").split("T");
        else
            return date.toISOString().slice(0, 10).replace(/-/g, "/");
    });
}

const DashboardHomeGraph: React.FC<DashboardHomeGraphProps> = ({ goToWalletPage }) => {

    const dbContext = useContext(AppContext);
    const [timePeriod, setTimePeriod] = React.useState("day");

    const columns = () => {
        switch (timePeriod) {
            case "day":
                return 12;
            case "week":
                return 7;
            case "month":
                return 15;
            case "quarter":
                return 18;
            case "year":
                return 12;
            default:
                return 0;
        }
    }

    const data = {
        labels: calculateLabels(timePeriod),
        datasets: [
            {
                label: '$',
                data: getWalletsTotalBalanceByPeriod(dbContext, false, timePeriod, columns()),
                fill: "start",
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 320);
                    gradient.addColorStop(0, "#ab4cfe");
                    gradient.addColorStop(1, "rgba(250,174,50,0)");
                    return gradient;
                },
                borderColor: "#ab4cfe",
                borderWidth: 4,
                pointBorderColor: "#ab4cfe",
                pointBackgroundColor: "white",
                pointHoverBackgroundColor: "white",
                pointHoverBorderColor: "#ab4cfe",
                pointBorderWidth: 4,
                pointHoverRadius: 12,
                pointHoverBorderWidth: 6,
                pointRadius: 8,
            }
            // Aggiungi altri dataset se necessario
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                stacked: true,
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value: any) {
                        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value.toFixed(2));
                    },
                    color: "white"
                }
            },
            x: {
                stacked: true,
                ticks: {
                    color: "white"
                }
            },
        },
        legend: {
            position: "bottom"
        },
        elements: {
            line: {
                tension: 0.35
            }
        },
        plugins: {
            filler: {
                propagate: false
            },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y.toFixed(2)
                        }
                        return label;
                    }
                }
            },
            legend: {
                display: false,
            }
        },
    };

    return (
        <>
            <Container fluid className="dashboard-container">
                <Row>
                    <Col className="text-start">
                        <h6 >Wallet</h6>
                    </Col>
                    <Col className="text-end">
                        <h6 className="text-button" onClick={() => goToWalletPage()}>+ Add Wallet</h6>
                    </Col>
                </Row>
                <Row className="small-top-bottom-padding">
                    <Col className="text-start only-mobile">
                        {dbContext.loading.price && (
                            <img className="loading-text" src={loading} />
                        )}
                        {!dbContext.loading.price && (
                            <h6 >$ {getWalletsTotalBalance(dbContext)}</h6>
                        )}
                    </Col>
                </Row>
                <Row className="small-top-bottom-padding align-items-center">
                    <Col md={7} className="text-start only-desktop">
                        {dbContext.loading.price && (
                            <img className="loading-text" src={loading} />
                        )}
                        {!dbContext.loading.price && (
                            <h6 >$ {getWalletsTotalBalance(dbContext)}</h6>
                        )}
                    </Col>
                    <Col md={5} className="text-end">
                        <Row className="row-timeperiod-selector">
                            <Col className="justify-content-center align-items-center" style={{ padding: 0 }}>
                                <h6 onClick={() => setTimePeriod("day")}
                                    className={`timeperiod-selector ${timePeriod == "day" ? "timeperiod-active" : ""} dark`}>24H</h6>
                            </Col>
                            <Col >
                                <h6 onClick={() => setTimePeriod("week")}
                                    className={`timeperiod-selector ${timePeriod == "week" ? "timeperiod-active" : ""} dark`}>7D</h6>
                            </Col>
                            <Col >
                                <h6 onClick={() => setTimePeriod("month")}
                                    className={`timeperiod-selector ${timePeriod == "month" ? "timeperiod-active" : ""} dark`}>30D</h6>
                            </Col>
                            <Col >
                                <h6 onClick={() => setTimePeriod("quarter")}
                                    className={`timeperiod-selector ${timePeriod == "quarter" ? "timeperiod-active" : ""} dark`}>90D</h6>
                            </Col>
                            <Col >
                                <h6 onClick={() => setTimePeriod("year")}
                                    className={`timeperiod-selector ${timePeriod == "year" ? "timeperiod-active" : ""} dark`}>1Y</h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <div className="graph-container">
                        <Line options={options} data={data} width={"100%"} style={{ height: "400px" }} />
                        {dbContext.loading.price && (
                            <div className="laoding-graph-container">
                                <img src={loadingGraph} className="loading-graph" />
                            </div>
                        )}
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default DashboardHomeGraph;