import { useContext } from "react";
import AppContext from "../../AppContext";
import infoImage from "../../images/info.svg";
import chartHover from "../../images/Chart-hover_1.svg";
import compositionChart from "../../images/Composition-chart.svg";
import {  getWalletTokensComposition, getWalletsTotalBalance } from "../../utils/crypto";
import { Doughnut } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { Col, Container, Row, Table } from "react-bootstrap";
import { TokenComposition} from "../../utils/Types";


export default function DashboardWalletComposition() {
    const dbContext = useContext(AppContext);

    const options: any = {
        stroke: {
            width: 0
        },
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    generateLabels: (chart: any) => {
                        const datasets = chart.data.datasets;
                        return datasets[0].data.map((data: any, i: number) => ({
                            text: `${chart.data.labels[i]} ${data} %`,
                            fontColor: 'white',
                            color: "#fff",
                            fillStyle: datasets[0].backgroundColor[i],
                            index: i
                        }))
                    },
                }
            }
        }
    };
    const graphData: any = {
        labels: [],
        datasets: [{
            label: 'Composition',
            data: [],
            backgroundColor: [
                '#ab4cfe',
                '#00cc66',
                '#ff69b4',
                '#762af5'
            ],
            borderWidth: [0, 0, 0, 0]

        }]
    };

    const configureData = (tokens: TokenComposition[]) => {
        const labels: string[] = [];
        const data: any = [];
        const totalWallets = getWalletsTotalBalance(dbContext, null, true, false) as number;
        tokens.slice(0, 3).forEach(token => {
            labels.push(token.symbol);
            data.push((token.value! / totalWallets * 100).toFixed(2));
        });
        const totalTopThree = data.reduce((partialSum: number, a: number) => partialSum + a, 0);
        if (totalTopThree >= totalWallets) {
            labels.push("Others");
            data.push(totalWallets - totalTopThree);
        }
        graphData.labels = labels;
        graphData.datasets[0].data = data;
    }
    const topTokens = () => {
        let tokens = getWalletTokensComposition(dbContext);
        tokens.sort((a, b) => b.value! - a.value!)
        configureData(tokens);
        return tokens.slice(0, 5).map(token => {
            return (
                <tr key={token.symbol} className=" flex-column">
                    <td><img src={token.icon} style={{ maxWidth: "25px" }} /></td>
                    <td>{token.name}<br /><span className="unactive">{token.symbol}</span></td>
                    <td>{token.amount.toFixed(5)}<br /><span className="unactive">$ {Intl.NumberFormat('en-US').format(token.value!)}</span></td>
                </tr>
            )
        });
    }


    return (
        <>
            <Container fluid className="dashboard-container">
                <Row>
                    <Col className="text-start">
                        <h5 className="dark">Composition</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Doughnut
                            data={graphData}
                            options={options}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="table-responsive">
                        <Table className="dark" variant="dark">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {topTokens()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            {/* <div className="div-block-34 _2">
                <div style={{ opacity: 0 }} className="popup-info-composition">
                    <div className="title-h2">
                        <img src={infoImage} loading="lazy" width="24" height="24" alt="" className="info-icon" />
                        <h1 className="title-h3">Composition</h1>
                    </div>
                    <p className="paragraph-2">Get insights into the breakdown of your portfolio’s asset allocation, helping you understand how your investments are distributed.</p>
                    <h1 data-w-id="1ff8cb08-241f-ae13-2565-9e86968fb188" className="text-button-2">Close</h1>
                </div>
                <div data-w-id="bc91e3f6-8311-70a3-5cab-d05a38c089fa" style={{ opacity: 0 }} className="composition-hover">
                    <img src={chartHover} loading="lazy" alt="" />
                </div>
                <div className="recent-t-2-copy">
                    <div className="title-with-button">
                        <div className="title-h2">
                            <div aria-current="page" className="link-block w-inline-block w--current">
                                <h1 className="h2-app">Composition</h1>
                            </div>
                            <img src={infoImage} loading="lazy" width="24" height="24" alt=""
                                data-w-id="1b682a23-9058-86d7-9585-66a6fac5a6f1" className="info-icon" />
                        </div>
                    </div>
                    <Doughnut
                        data={graphData}
                        options={options}
                    />
                    <img src={compositionChart} loading="lazy" alt="" className="image-22" />
                    <table className="table table-dark table-hover">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {topTokens()}
                        </tbody>
                    </table>
                </div>
            </div> */}
        </>
    )
}