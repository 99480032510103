import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { addNewWallet, deleteWallet, loadDbData, updateWalletVisibility } from "../../utils/firebase";
import AppContext from "../../AppContext";
import { getExternalUrl, getSingleWalletBalance, getWalletsTotalBalance, identifyBlockchain } from "../../utils/crypto";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { Chain, WalletDB } from "../../utils/Types";
import loading from '../../images/loading-text.gif';
import { useWeb3Modal } from "@web3modal/wagmi1/react";
import { useAccount } from "wagmi";
import { disconnect } from "@wagmi/core";




export default function DashboardWalletManagement() {
    const [showNewAddress, setShowNewAddress] = React.useState(false);
    const [showRemoveAddress, setShowRemoveAddress] = React.useState("");
    const [newAddress, setNewAddress] = React.useState("");
    const [newName, setNewName] = React.useState("");
    const [newChainType, setnewChainType] = React.useState(Chain.ETH);
    const dbContext = useContext(AppContext);
    const { open, close } = useWeb3Modal()
    const { address, isConnecting, isDisconnected } = useAccount()


    useEffect(() => {
        console.log(address);
        if (!!address) {
            setNewAddress(address?.toString() || "");
            disconnect();
            openModal();
        }
    }, [address]);

    const openModal = () => {
        document.getElementById("root")!.style.filter = "blur(2px)";
        setShowNewAddress(true);
    }

    const addNewAddress = async () => {
        if (identifyBlockchain(newAddress) != newChainType && newAddress) return;
        if (dbContext.wallets.find((wallet: WalletDB) => wallet.address == newAddress)) return;
        await addNewWallet(newName, newAddress, newChainType, dbContext);
        setShowNewAddress(false);
        setNewAddress("");
        setNewName("");
        setnewChainType(Chain.ETH);
        document.getElementById("root")!.style.filter = "none";
        loadDbData(dbContext);
    }

    const hideShowNewAddress = () => {
        setShowNewAddress(false);
        setNewAddress("");
        setNewName("");
        setnewChainType(Chain.ETH);
        document.getElementById("root")!.style.filter = "none";
    }
    const deleteAddress = async () => {
        const walletToDelete = dbContext.wallets.find((wallet: WalletDB) => wallet.address == showRemoveAddress);
        await deleteWallet(walletToDelete.id, dbContext);
        setShowRemoveAddress("");
    }

    const toggleWalletVisibility = (address: string) => {
        const oldWallets = dbContext.wallets;
        for (let i = 0; i < oldWallets.length; i++) {
            if (oldWallets[i].address == address) {
                oldWallets[i].visibility = !oldWallets[i].visibility;
                updateWalletVisibility(oldWallets[i].id, oldWallets[i].visibility);
                break;
            }
        }
        dbContext.setWallets([...oldWallets]);
    }

    return (
        <>
            <Modal show={showNewAddress} onHide={hideShowNewAddress} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add new Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}>
                    <Row>
                        <Col>
                            <input type="text" className="_100-input w-input"
                                max="256" name="name" data-name="Name"
                                placeholder="Name" id="name" required={true}

                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input type="text" className="_100-input w-input"
                                max="256" name="address" data-name="Address"
                                placeholder="Address" id="address" required={true}

                                value={newAddress}
                                onChange={(e) => { setNewAddress(e.target.value); }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <select value={newChainType} onChange={(e) => { setnewChainType(Chain[e.target.value as keyof typeof Chain]); }}>
                                {Object.keys(Chain).map(key => (<option key={key} value={key}>{key}</option>))}
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span style={{ color: "red", fontWeight: "800" }}>{identifyBlockchain(newAddress) != newChainType && newAddress != "" ?
                                "The address entered is not an address of type " + newChainType : ""}</span>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center align-items-center">
                    <Button className="button dark-outline-button"
                        onClick={(e) => addNewAddress()}
                        disabled={!newName || !newAddress || !!(identifyBlockchain(newAddress) != newChainType)}
                    >+ Add</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showRemoveAddress != ""} onHide={() => setShowRemoveAddress("")} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Remove Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}>
                    <Row>
                        <Col>
                            <span style={{ fontWeight: "800" }}>Are you sure you want to remove the wallet with address {showRemoveAddress}</span>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center align-items-center">
                    <Button className="button dark-outline-button"
                        onClick={(e) => deleteAddress()}
                    >Remove</Button>
                </Modal.Footer>
            </Modal>


            <Container fluid className="dashboard-container">
                <Row className="align-items-center small-bottom-padding">
                    <Col className="text-start" xs={12} sm={2}>
                        <h5 className="dark">Wallets</h5>
                    </Col>
                    <Col className="text-end" xs={12} sm={10}>
                        <Row>
                            <Col xs={12} sm={"auto"} className="small-bottom-padding">
                                <Button className="button dark-button margin-auto"
                                    onClick={() => open()}
                                >+ Connect Wallet</Button>
                            </Col>
                            <Col xs={12} sm={"auto"} className="small-bottom-padding">
                                <Button className="button dark-button margin-auto"
                                    onClick={() => openModal()}
                                >+ Manually Add Wallet</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="table-responsive">
                        <Table className="dark" variant="dark">
                            <thead>
                                <tr>
                                    <th scope="col" className="col-xs-12">Name</th>
                                    <th scope="col" className="col-xs-12">Address</th>
                                    <th scope="col" className="col-xs-12">Visibility</th>
                                    <th scope="col" className="col-xs-12">Type</th>
                                    <th scope="col" className="col-xs-12">Balance</th>
                                    <th scope="col" className="col-xs-12"></th>
                                    <th scope="col" className="col-xs-12"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dbContext.wallets.map((wallet: WalletDB) => {
                                    return (
                                        <tr key={wallet.id} className=" flex-column">
                                            <td>{wallet.name}</td>
                                            <td style={{ color: wallet.visibility ? "inherit" : "#82769d" }}>{wallet.address}</td>
                                            <td>
                                                <span className="material-symbols-outlined" style={{ display: !wallet.visibility ? "" : "none", cursor: "pointer" }} onClick={() => toggleWalletVisibility(wallet.address)}>
                                                    visibility_off
                                                </span>
                                                <span className="material-symbols-outlined" style={{ display: wallet.visibility ? "" : "none", cursor: "pointer" }} onClick={() => toggleWalletVisibility(wallet.address)}>
                                                    visibility
                                                </span>
                                            </td>
                                            <td>{wallet.chain}</td>
                                            <td>{getSingleWalletBalance(dbContext, wallet.address)} $</td>
                                            <td><span className="bold text-button" onClick={() => setShowRemoveAddress(wallet.address)}>Remove</span></td>
                                            <td><Link to={getExternalUrl(wallet.chain, "address", wallet.address)} target="_blank" className="dark">On Chain <b>&rarr;</b></Link></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="small-bottom-padding">
                    <Col>
                        {dbContext.loading.price && (
                            <img className="loading-text" src={loading} />
                        )}
                        {!dbContext.loading.price && (
                            <h6 className="dark">Total    $ {getWalletsTotalBalance(dbContext)}</h6>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    )
}