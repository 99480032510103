import { Col, Container, Row } from "react-bootstrap";
import { getWalletsTotalBalance } from "../../utils/crypto";
import { useContext } from "react";
import AppContext from "../../AppContext";
import loading from '../../images/loading-text.gif';


export default function DashboardStakingView() {
    const dbContext = useContext(AppContext);

    return (
        <Container fluid>
            <Row>
                <Col className="text-start">
                    <h4 className="dark">My assets</h4>
                </Col>
                <Col className="text-end">
                    {dbContext.loading.price && (
                        <img className="loading-text" src={loading} />
                    )}
                    {!dbContext.loading.price && (
                        <h4 className="dark">
                            $ {getWalletsTotalBalance(dbContext)}
                        </h4>
                    )}
                </Col>
            </Row>
            <Row className="top-bottom-padding">
                <Col className="text-center">
                    <h2 className="dark">Coming Soon...</h2>
                </Col>
            </Row>
        </Container>
    );
}