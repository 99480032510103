
import Header from "./components/Header";
import Footer from "./components/Footer";
import DashboardMenu from "./components/DashboardMenu";
import DashboardGraphView from "./components/dashboardGraph/DashboardGraphView";
import DashboardWalletView from "./components/dashboardWallet/DashboardWalletView";
import React, { useEffect, useState, useContext } from "react";
import AppContext from "./AppContext";
import { auth, loadDbData } from "./utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getMarketCapData, getTopRakingCoins } from "./utils/crypto";
import DashboardMarketView from "./components/dashboardMarket/DashboardMarketView";
import { Col, Container, Row } from "react-bootstrap";
import DashboardStakingView from "./components/dashboardStaking/DashboardStakingView";



export default function Dashboard() {
    const [menuIndex, setMenuIndex] = React.useState(0);
    const [firstRead, setFirstRead] = React.useState(false);
    const [user, loading, error] = useAuthState(auth);

    const dbContext = useContext(AppContext);

    const goToWalletPage = () => {
        setMenuIndex(1);
    }

    const componentToRender = () => {
        switch (menuIndex) {
            case 0:
                return <DashboardGraphView goToWalletPage={goToWalletPage} />;
            case 1:
                return <DashboardWalletView />;
            case 2:
                return <DashboardStakingView />;
            case 3:
                return <DashboardMarketView />
        }
    }

    useEffect(() => {
        if (user && !firstRead) {
            setFirstRead(true);
            loadDbData(dbContext);
            getMarketCapData(dbContext);
            getTopRakingCoins(dbContext);
        };
    }, [user, loading, dbContext]);


    return (
        <Container fluid>
            <Header accessRequired={true} />
            <Container fluid>
                <Row>
                    <Col xs={12} md={3}>
                        <DashboardMenu
                            menuIndex={menuIndex}
                            setMenuIndex={setMenuIndex}
                        />
                    </Col>
                    <Col xs={12} md={9}>
                        {componentToRender()}
                    </Col>
                </Row>
            </Container>
            <Footer />
        </Container>
    )
}