import '../css/style.css';
import logoDark from '../images/logo-dark.svg';
import { logInWithEmailAndPassword, auth } from '../utils/firebase';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, Offcanvas, Modal, Row, Col, CloseButton } from 'react-bootstrap';


export default function Header({ accessRequired = false }) {
    const location = useLocation();
    const isInDashboard = location.pathname.includes("dashboard");
    const navigate = useNavigate();
    const [showLogin, setShowLogin] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [user, loading, error] = useAuthState(auth);
    if (!user && accessRequired) {
        navigate("/");
    }


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const openModal = () => {
        document.getElementById("root")!.style!.filter = "blur(2px)";
        setShowLogin(!showLogin)
    }
    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (user) {
            console.log("User id: " + user.uid);
        };
    }, [user, loading]);


    const login = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const response = await logInWithEmailAndPassword(email, password);
        if (response) {
            document.getElementById("root")!.style!.filter = "none";
            navigate("/dashboard");
        }
    }
    const hideShowLogin = () => {
        document.getElementById("root")!.style!.filter = "none";
        setShowLogin(false);
        setEmail("");
        setPassword("");
    }

    return (
        <>
            <Modal show={showLogin} onHide={hideShowLogin} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Log In</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}>
                    <Row>
                        <Col>
                            <input type="email" className="_100-input w-input"
                                max="256" name="email" data-name="Your Email 2"
                                placeholder="Email" id="email" required={true}

                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <input type="password" className="password w-input"
                                max="256" name="password" data-name="Your Email 2"
                                placeholder="Password" id="password" required={true}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center align-items-center">
                    <Button className="button dark-outline-button"
                        onClick={(e) => login(e)}
                    ><span className="text-span"></span> Log In</Button>
                </Modal.Footer>
            </Modal>

            <Container className='menu-container' fluid>
                <Navbar expand="md" className="navbar dark navbar-dark mb-3">
                    <Container fluid>
                        <Navbar.Brand href="/">
                            <img src={logoDark} loading="lazy" alt="" className="logo-light-website" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} onClick={handleShow} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-md`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                            placement="end"
                            show={show} onHide={handleClose}
                        >
                            <Offcanvas.Header >
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`} className='dark'>
                                    Menu
                                </Offcanvas.Title>
                                <CloseButton onClick={handleClose} variant='white' />
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-center align-items-center flex-grow-1 pe-3 menu">
                                    {/* {!isInDashboard && (
                                        <> */}
                                    <Nav.Link href="/" className='nav-link-dark'>Home</Nav.Link>
                                    <Nav.Link href="/about" className='nav-link-dark'>About</Nav.Link>
                                    <Nav.Link href="/features" className='nav-link-dark'>Features</Nav.Link>
                                    {/* </>
                                    )} */}
                                </Nav>
                                {/* {isInDashboard && (
                                    <img src={profileImage} loading="lazy" alt="" />
                                )} */}

                                {!isInDashboard && (<>
                                    <Button className="button dark-outline-button"
                                        style={{ display: !user ? "block" : "none" }}
                                        onClick={() => openModal()}
                                    ><span className="text-span"  ></span> Log In</Button>
                                    <Button className="button dark-outline-button"
                                        style={{ display: user ? "block" : "none" }}
                                        href="/dashboard"
                                    ><span className="text-span"  ></span> Dashboard</Button>
                                    <Button className="button dark-button"
                                        href="/contact"
                                    >Contact</Button>
                                </>)}
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </Container>

            {/* <div className="w-layout-blockcontainer header w-container">
                        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
                            <div className="container w-container">
                                <Link to="/" aria-current="page" className="brand w-nav-brand w--current">
                                    <img src={logoDark} loading="lazy" alt="" className="logo-light-website" />
                                </Link>
                                <nav role="navigation" className="nav-menu w-nav-menu">
                                    <Link to="/" aria-current="page" className="navlink w-nav-link">Home</Link>
                                    <Link to="/about" className="navlink w-nav-link">About</Link>
                                    <Link to="/features" className="navlink w-nav-link">Features</Link>
                                </nav>
                                <div className="w-nav-button">
                                    <div className="w-icon-nav-menu"></div>
                                </div>
                                <div className="div-block">
                                    <Link data-w-id="0091fcd4-72d5-e536-35ba-5888a2b45a57"
                                        className="button-2 w-button" onClick={() => setShowLogin(!showLogin)}
                                        style={{ display: !user ? "block" : "none" }}>
                                        <span className="text-span"  ></span> Log In
                                    </Link>
                                    <Link to="/dashboard" data-w-id="0091fcd4-72d5-e536-35ba-5888a2b45a57"
                                        className="button-2 w-button" style={{ display: user ? "block" : "none" }}>
                                        <span className="text-span"  ></span> Dashboard
                                    </Link>
                                    <Link to="/contact" className="button w-button">Contact</Link>
                                </div>
                            </div>
                        </div>
                        <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar-responsive w-nav">
                            <div className="container w-container">
                                <Link href="/" aria-current="page" className="brand-header w-nav-brand w--current">
                                    <img src={logoDark} loading="lazy" alt="" className="logo-dark" />
                                </Link>
                                <nav role="navigation" className="nav-menu w-nav-menu">
                                    <Link href="/" aria-current="page" className="navlink-dropdown w-nav-link">Home</Link>
                                    <Link href="/about" className="navlink-dropdown w-nav-link">About</Link>
                                    <Link href="/features" className="navlink-dropdown w-nav-link">Features</Link>
                                    <Link href="/contact" className="navlink-dropdown w-nav-link">Contact</Link>
                                    <Link data-w-id="bc2b3448-ed2a-3aa9-cbfd-296e08c818de" href="#" className="navlink-dropdown w-nav-link">Log in</Link>
                                </nav>
                                <div className="div-block">
                                    <div className="menu-button white w-nav-button">
                                        <div className="white w-icon-nav-menu"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

            {/* {isInDashboard && (
                <div className="columns-2 w-row">
                    <div className="column w-col w-col-3 w-col-small-3 w-col-tiny-tiny-stack">
                        <div className="div-block-25">
                            <div className="logo">
                                <Link to="/" aria-current="page" >
                                    <div className="logo-dark">
                                        <img src={logoDark} loading="lazy" alt="" className="image-14" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="column-4 w-col w-col-9 w-col-small-9 w-col-tiny-tiny-stack">
                        <div className="header-platform">
                            <div className="switch">
                                <a href="wallet-light.html" className="switch-dark-wallet w-inline-block">
                                    <img src={switchDark} loading="lazy" width="54" alt="" />
                                </a>
                            </div>
                            <div className="icon-dark">
                                <img src={notificationImage} loading="lazy" alt="" />
                            </div> 
                            <div className="profile">
                                <img src={profileImage} loading="lazy" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            )} */}
        </>
    );
}