import { useContext } from "react";
import AppContext from "../../AppContext";
import DashboardMarketTopCoins from "./DashboardMarketTopCoins";
import { Col, Container, Row } from "react-bootstrap";

export default function DashboardMarketNews() {
    const dbContext = useContext(AppContext);
    const latestNews = () => {
        let newsIndex = 0;
        const rowItems: any = [];
        dbContext.news.forEach((news: any) => {
            rowItems.push(
                <a href={news.url} key={newsIndex}>
                    <div className="news-item">
                        <img className="news-image" src={news.urlToImage} />
                        <br /><b><span>{news.title || "Default Title"}</span></b>
                        <br /><span>{news.description || "Default Description"}</span>
                    </div>
                </a>
            );
            newsIndex++;
        });
        return rowItems;
    }

    return (
        <Container fluid className="dashboard-container">
            <Row>
                <Col className="text-start small-bottom-padding">
                    <h5 className="dark">Latest News</h5>
                </Col>
            </Row>
            <Row>
                <Col className="news-container">
                    {latestNews()}
                </Col>
            </Row>
        </Container>
    );
}